import {
    ActionID,
    AppIdentifiers,
    AreaType,
    IControlCenterFullSpecificParams,
    ILogger,
    IPlayerChannel,
    MessageBroker,
    TriggerType,
    ElementType,
    SourceType,
    ControlCenterOpenSource,
} from '@sparkware/uc-sdk-core';
import Container, { Service } from 'typedi';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { ILoginOptions } from '../authentication/models';
import { ClientsFrameworkRegistrationService } from '../external/clients-framework';
import { LoggerProvider } from '../logger';
import { SessionSimpleStoreService } from '../storage/implementations/simple-store';
import { Utils } from '../utils';
import { IActionHandlerFactory } from '../action-handler/models/IActionHandlerFactory';
import { Registration } from '../registration/registration';
import { LoaderManager } from '../../loaders/LoaderManager';
import { WindowToken } from '../../injection-tokens';

@Service()
export class NavigationService {
    private readonly _window: Window;
    private readonly _logger: ILogger;
    private readonly _playerChannel: IPlayerChannel;
    private readonly _registrationService: ClientsFrameworkRegistrationService;
    private readonly _utils: Utils;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;
    protected readonly _registration: Registration;

    private get _actionHandlerFactoryPromise(): Promise<IActionHandlerFactory> {
        return LoaderManager.Instance.ActionHandlerFactoryLoader.Instance;
    }

    constructor() {
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('NavigationService');
        this._playerChannel = MessageBroker.getInstance().player;
        this._registrationService = Container.get(ClientsFrameworkRegistrationService);
        this._utils = Container.get(Utils);
        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
        this._registration = Container.get(Registration);
    }

    public openLogin = (isAuthenticated: boolean) => {
        if (!isAuthenticated) {
            this._playerChannel.topics.loginCompletion.publish(
                {
                    publisher: 'Navigation',
                },
                null,
            );
        }
    };

    public openNRS = (isAuthenticated: boolean, promo: string) => {
        if (!isAuthenticated) {
            if (promo) {
                this._sessionSimpleStoreService.set(StorageItemEnum.CashierPromoCode, promo);
            }

            const messageData = {
                onSuccess: () => {
                    this._registration.openRegistration();
                },
                onFail: () => {
                    this._logger.debug('registerCompletion failed');
                    return;
                },
            };
            if (typeof this._window.onsCutInitialized === 'function') {
                try {
                    this._window.onsCutInitialized(() => {
                        this._registration.openRegistration();
                    });
                } catch (err) {
                    this._logger.error(
                        `Failed to pass callback for sCut, err: ${err.message}`,
                        err,
                    );
                    this._registration.openRegistration();
                }
            } else {
                this._registration.openRegistration();
            }
        }
    };

    public loginToCashier = (isAuthenticated: boolean, promo: string) => {
        if (!isAuthenticated) {
            if (promo) {
                this._sessionSimpleStoreService.set(StorageItemEnum.CashierPromoCode, promo);
            }

            const loginOptions: ILoginOptions = {
                postLogin: {
                    OpenCashier: {
                        enabled: true,
                        promo,
                    },
                },
            };

            this._playerChannel.topics.loginCompletion.publish(
                {
                    publisher: 'Navigation',
                },
                loginOptions as any,
            );
        }
    };

    public openControlCenter = async (options: IControlCenterFullSpecificParams) => {
        const actionHandlerFactory = await this._actionHandlerFactoryPromise;
        await actionHandlerFactory.performAction({
            actionID: ActionID.openControlCenterFull,
            correlationID: this._utils.generateCorrelationID(),
            launchInfo: {
                businessCorrelationID: this._utils.generateCorrelationID(),
                sourceAppID: AppIdentifiers.UnifiedClient,
                sourceAppVersion: '0.0.0',
                channel: {
                    area: AreaType.Email,
                    source: SourceType.DeepLink,
                    element: ElementType.UD,
                },
                appSpecificParameters: {
                    clientPlatform: 1,
                    source: ControlCenterOpenSource.Email,
                    ...options,
                },
                trigger: TriggerType.userSelection,
            },
            actionData: {},
        });
    };
}

import {
    IAutoLoginResponseModel,
    IRegularLoginResponseModel,
} from '../src/modules/authentication/models';
import { ApiResponse, HTTPVerb } from '../Modules/Utils/API/Interfaces/IApi';
import { ApiManager } from '../Modules/Utils/API/ApiManager';

enum APIConstants {
    ManualLogin = '/api/authentication/login/',
    AutoLogin = '/api/authentication/auto-login/',
    DecryptUserName = '/api/ManualLogin/DecryptUserName/',
    EncryptUserName = '/api/ManualLogin/EncryptUserName/',
    MigrationAutoLogin = '/api/authentication/migration-auto-login',
}

export default class AuthenticationApi {
    public static Login = async (data): Promise<ApiResponse<IRegularLoginResponseModel, any>> =>
        ApiManager.getAPIInstance().request({
            url: APIConstants.ManualLogin,
            method: HTTPVerb.POST,
            data,
        });

    public static DecryptUserName = async (data): Promise<ApiResponse<any, any>> =>
        ApiManager.getAPIInstance().request({
            url: APIConstants.DecryptUserName,
            method: HTTPVerb.POST,
            data,
        });

    public static EncryptUserName = async (data): Promise<ApiResponse<any, any>> =>
        ApiManager.getAPIInstance().request({
            url: APIConstants.EncryptUserName,
            method: HTTPVerb.POST,
            data,
        });

    public static AutoLogin = async (data): Promise<ApiResponse<IAutoLoginResponseModel, any>> =>
        ApiManager.getAPIInstance().request({
            url: APIConstants.AutoLogin,
            method: HTTPVerb.POST,
            data: data,
        });

    public static MigrationAutoLogin = async (
        data,
    ): Promise<ApiResponse<IAutoLoginResponseModel, any>> =>
        ApiManager.getAPIInstance().request({
            url: APIConstants.MigrationAutoLogin,
            method: HTTPVerb.POST,
            data: data,
        });
}

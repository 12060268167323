import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WindowToken } from '../../../injection-tokens';

@Service()
export class UserareaService {
    private readonly _logger: ILogger;
    private readonly _window: Window;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('UserareaService');
        this._window = Container.get(WindowToken);
    }

    public executeOnload = async (callback: () => any) => {
        return this._window.libraryManager.UserareaLibrary.ready.then(() => callback());
    };

    public findIfIsLoaded = (): boolean => {
        return this._window.libraryManager.UserareaLibrary.isReady;
    };
}

export enum NativeAppOSType {
    Android = 'Android',
    iOS = 'iOS',
}

export enum NativeElasticEvents {
    DataStoreReady = 'native-data-store-ready',
    GetBrandProduct = 'native-get-brand-product',
    UrbanAirshipSegmentation = 'native-urban-airship-segmentation',
    Init = 'native-init',
    OnNativeReady = 'native-on-native-ready',
    OnGetInfoComplete = 'native-on-get-info-complete',
    GetiOSNativeDeviceInfo = 'native-get-iOS-native-device-info',
    OnShowTouchId = 'native-on-show-touch-id',
    OnGetCid = 'native-on-get-cid',
    UserLoggedIn = 'native-user-logged-in',
    CheckGeoComply = 'native-check-geo-comply',
    PerformActionResult = 'native-perform-action-result',
    SetPlayerState = 'native-set-player-state',
    ShowBottomBar = 'native-show-bottom-bar',
    BetsUpdate = 'native-bets-update',
    ThemeModeUpdate = 'native-theme-mode-update',
    triggerHapticFeedback = 'native-trigger-haptic-feedback',
    OnLocationResponse = 'native-on=location-response',
    OnLocationPermissionResponse = 'native-on-location-permission-response',
    OnGeoLocationResponseSuccess = 'native-on-geo-location-response-success',
    OnGeoLocationResponseError = 'native-on-geo-location-response-error',
    OnBackToForeground = 'native-on-back-to-foreground',
    AppInBackground = 'native-app-in-background',
    SetInfoForWeb = 'native-set-info-for-web',
    GetAndroidNativeDeviceInfo = 'native-device-get-android-native-device-info',
    GetBiometricsFlowSettings = 'native-get-biometrics-flow-settings',
    GetAuthorizeData = 'native-get-authorize-data',
    SetPushNotificationTag = 'native-set-push-notification-tag',
    SetPushNotificationTagGroup = 'native-set-push-notification-tag-group',
    SetPushNotificationRiskTag = 'native-set-push-notification-risk-tag',
    OnGetInfoResponse = 'native-on-get-info-response',
}

export enum MBRollout {
    GetInfo = 1,
    UI = 2,
    NativeChannel = 3,
    Geolocation = 4,
    Push = 5,
}

import { WindowToken } from '../../injection-tokens';
import { Container } from 'typedi';
import { LoggerProvider } from '../logger';
import { PlatformLogoutReason, StaticPaths } from '../../models/enums/general-enums';
import { RouterService } from '../router/router.service';
import { ViewInjector } from '../view-injector';
import { ClientsFrameworkLogoutService } from '../external/clients-framework';
import { BrandUtils } from '../../../Modules/Utils/BrandUtils';
import { IIdentity } from './models/IIdentity';
import { ILogger } from '@sparkware/uc-sdk-core';

export class Identity implements IIdentity {
    private readonly _window: Window;
    private readonly _logger: ILogger;
    private readonly _routerService: RouterService;
    private readonly _viewInjector: ViewInjector;
    private readonly _logoutService: ClientsFrameworkLogoutService;

    constructor() {
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('Identity');
        this._routerService = Container.get(RouterService);
        this._viewInjector = Container.get(ViewInjector);
        this._logoutService = Container.get(ClientsFrameworkLogoutService);
        this.handlePopStateEvents = this.handlePopStateEvents.bind(this);
    }

    public onLogout = async (): Promise<void> => {
        await this._viewInjector.OnAuthenticationStatusChangeContentUpdate();
    };

    public onLoginSuccess = async (): Promise<void> => {
        await this._viewInjector.OnAuthenticationStatusChangeContentUpdate();
    };

    public async handlePopStateEvents(): Promise<void> {
        if (BrandUtils.isPokerClient()) {
            void this._routerService.NavigateTo(StaticPaths.PokerRoot);
        } else {
            void this._routerService.NavigateTo(StaticPaths.Root);
        }
        this._window.removeEventListener('popstate', this.handlePopStateEvents);
        this._window.document.querySelector('.main-loader')?.remove();
    }

    public onUserAuthenticationFailed = async ({ error }): Promise<void> => {
        this._logger.debug('userAuthenticationFailed message received. error: ', error);
        let logoutReason = PlatformLogoutReason.LoginError;
        void this._logoutService.doLogout(logoutReason);
        this._window.addEventListener('popstate', this.handlePopStateEvents);
    };
}

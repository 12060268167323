import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { ILogger, IPerformActionChannel, MessageBroker } from '@sparkware/uc-sdk-core';
import { IActionHandlerFactory } from '../../action-handler/models/IActionHandlerFactory';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { IAction } from '@sparkware/uc-sdk-core/lib/message-broker/action.interface';

@Service()
export class PerformActionChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _performActionChannel: IPerformActionChannel;
    private get _actionHandlerFactoryPromise(): Promise<IActionHandlerFactory> {
        return LoaderManager.Instance.ActionHandlerFactoryLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('PaymentsChannelSubscriber');
        this._performActionChannel = MessageBroker.getInstance().performAction;
        this.addRegisterResolverToEveryAction();
    }

    private addRegisterResolverToEveryAction() {
        //In order to support all actions as we have on NavigationChannel.performAction
        for (const key in this._performActionChannel.topics) {
            try {
                (this._performActionChannel.topics[key] as IAction<any, any>).registerResolver(
                    async (payload) => {
                        await this.onPerformAction(key, payload);
                    },
                );
            } catch (err) {
                this._logger.error(`[addRegisterResolverToEveryAction] failed`, err);
            }
        }
    }

    private async onPerformAction(actionID: string, data: any) {
        const actionHandlerFactory = await this._actionHandlerFactoryPromise;
        // when perform action coming from PerformAction Channel, it arrives without the actionID
        await actionHandlerFactory.performAction({ actionID, ...data });
    }
}

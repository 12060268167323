export enum WebVitalsName {
    CLS = 'CLS',
    FID = 'FID',
    LCP = 'LCP',
    FCP = 'FCP',
    TTFB = 'TTFB',
}

export enum ClickStreamEventInnerType {
    WebVitals = 'web-vitals',
    Analytics = 'analytics',
}

export enum TrackingElasticEvent {
    PerformAction = 'perform-action',
    WindowMessage = 'window-message',
    Mb2CbPerformAction = 'mb-2-cb-perform-action',
    Mb2CbMinimizeApp = 'mb-2-cb-minimize-app',
    Mb2CbPassCloseIntent = 'mb-2-cb-pass-close-intent',
    Mb2CbPassAppClosed = 'mb-2-cb-pass-app-closed',
    mb2cbPassUpdateApp = 'mb-2-cb-pass-update-app',
    B2CIntegrationShowCookieBanner = 'b2c-integration-show-cookie-banner',
    B2CIntegrationHideCookieBanner = 'b2c-integration-hide-cookie-banner',
    B2CIntegrationPlayerSegmentationPublish = 'b2c-integration-player-segmentation-publish',
    B2CIntegrationGetCookieContainer = 'b2c-integration-get-cookie-container',
    B2CIntegrationOpenMyAccount = 'b2c-integration-open-my-account',
    B2CIntegrationOpenMyChallenges = 'b2c-integration-open-my-challenges',
    B2CIntegrationOpenExternalLink = 'b2c-integration-open-external-link',
    B2CIntegrationOpenCashier = 'b2c-integration-open-cashier',
    B2CIntegrationOpenRich = 'b2c-integration-open-rich',
    B2CIntegrationOpenPokerRaces = 'b2c-integration-open-poker-races',
    B2CIntegrationOpenRedeem = 'b2c-integration-open-redeem',
    B2CIntegrationOpenCasino = 'b2c-integration-open-casino',
    B2CIntegrationOpenSport = 'b2c-integration-open-sport',
    B2CIntegrationOpenClientPage = 'b2c-integration-open-client-page',
}

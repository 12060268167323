import { Service, Token } from 'typedi';
import { MarketingBrand } from '../../models/enums/general-enums';
import { IMrGreenMigration } from './models/interfaces/IMrGreenMigration';
import { Lock } from '../utils/lockUtil';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const MrGreenMigrationToken = new Token<IMrGreenMigration>('MrGreenMigration');

@Service()
export class MrGreenMigrationLoader extends BaseModuleLoader<IMrGreenMigration> {
    constructor() {
        super('MrGreenMigrationLoader');
    }

    protected _createModuleInstance = async (): Promise<IMrGreenMigration> => {
        const { MrGreenMigration } = await import(
            /* webpackChunkName: "migration/mrgreen" */
            './mr-green-migration'
        );

        return new MrGreenMigration();
    };

    protected override _canUse: () => Promise<boolean> = async () => {
        const { marketingBrandId } = this._window.pageContextManager.getBrandData();

        const canUse = marketingBrandId == MarketingBrand.MrGreen;

        if (!canUse) {
            this._logger.debug(
                'Mr Green module will no loaded since marketing brand is' + marketingBrandId,
            );
        }

        return canUse;
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IMrGreenMigration> => MrGreenMigrationToken;
}

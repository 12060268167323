import ApiConstants from '../Constants/ApiConstants';
import { AxiosRequestConfig } from 'axios';

/**
 * Get current axios configuration for axios api requests
 * It automatically chooses between default or service mode one
 */
const getDefaultConfiguration = (windowToken: Window): AxiosRequestConfig => {
    const serviceUrl = windowToken?.pageContextManager.getSiteData().serviceUrl;
    let baseURL = new URL(serviceUrl || windowToken?.location.origin).origin;
    return {
        timeout: ApiConstants.DefaultTimeout,
        baseURL,
    };
};

/**
 * Get default axios configuration for axios api requests
 */
const getNonServiceConfiguration = (): AxiosRequestConfig => {
    return {
        timeout: ApiConstants.DefaultTimeout,
    };
};

export default { getDefaultConfiguration, getNonServiceConfiguration };

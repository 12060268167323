import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { IClientsFrameworkAutologinService } from './interfaces';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WindowToken } from '../../../injection-tokens';

@Service()
export class ClientsFrameworkAutologinService implements IClientsFrameworkAutologinService {
    protected readonly _logger: ILogger;
    private readonly _window: Window;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ClientsFrameworkAutologinService');
        this._window = Container.get(WindowToken);
    }

    public SetBackButtonData = async (data: any): Promise<void> => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() => {
            this._window.PF.Web.ClientsFramework.AutoLogin.SetBackButtonData(data);
        });
    };
}

import { LoggerProvider } from '../../logger';
import { TouchIdService } from '../../native/touch.id.service';
import Container, { Service } from 'typedi';
import { ILogger, INativeChannel, LifecycleEnum, MessageBroker } from '@sparkware/uc-sdk-core';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { LocalSimpleStoreService } from '../../storage/implementations/simple-store';
import { MBRollout } from '../../native/enums/consts';
import { NativeUtils } from '../../native/native.utils';
import { IGeolocation } from '../../geolocation/models/IGeolocation';
import { IAppLifecycleData } from '@sparkware/uc-sdk-core/lib/message-broker/channels/native/native.channel.interfaces';

@Service()
export class NativeChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _nativeChannel: INativeChannel;
    private readonly _touchIdService: TouchIdService;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;

    private get _geolocationPromise(): Promise<IGeolocation> {
        return LoaderManager.Instance.GeolocationLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('NativeChannelSubscriber');
        this._nativeChannel = MessageBroker.getInstance().native;
        this._touchIdService = Container.get(TouchIdService);
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);

        this._nativeChannel.topics.TouchIdPostLoginFlow.subscribe(
            this.onTouchIdPostLoginFlow.bind(this),
        );
        this._nativeChannel.topics.ResetTouchId.subscribe(this.onResetTouchId.bind(this));
        this._nativeChannel.topics.SaveTouchIdToken.subscribe(this.onSaveTouchIdToken.bind(this));
        this._nativeChannel.topics.SaveSuggestedStatus.subscribe(
            this.onSaveSuggestedStatus.bind(this),
        );
        this._nativeChannel.topics.updateAppLifecycleStatus.subscribe(
            this.onUpdateAppLifecycleStatus.bind(this),
        );
    }

    private onTouchIdPostLoginFlow() {
        this._touchIdService.touchIdPostLoginFlow();
    }
    private onResetTouchId() {
        this._touchIdService.resetTouchId();
    }

    private onSaveTouchIdToken(data: any) {
        if (data) {
            this._touchIdService.saveTouchIdToken(data.callback);
        }
    }

    private onSaveSuggestedStatus() {
        this._touchIdService.saveSuggestedStatus();
    }

    private async onUpdateAppLifecycleStatus(data: IAppLifecycleData) {
        const nativeMBRollout = Number(this._localSimpleStoreService.get('NativeMBRollout'));
        if (nativeMBRollout >= MBRollout.NativeChannel) {
            switch (data.status) {
                case LifecycleEnum.Background:
                    await NativeUtils.appInBackground();
                    break;
                case LifecycleEnum.Foreground:
                    await NativeUtils.onBackToForeground();

                    const geolocation = await this._geolocationPromise;
                    if (geolocation) {
                        geolocation.initFlowAfterRefresh();
                    }

                    break;
                default:
                    break;
            }
        }
    }
}

import Container, { Service } from 'typedi';
import UserApi from '../../../../APIs/UserApi';
import { IFeatureEligibility } from './feature-eligibility-interface';
import { AppEligibilityPropertyEnum } from '../constants';
import {
    IEligibilityResponseWrapper,
    IGetEligibilityRequest,
    IUnifiedCashierEligibilityResponse,
} from './interfaces';
import { LoggerProvider } from '../../logger';
import { WindowToken } from '../../../injection-tokens';
import { ILogger } from '@sparkware/uc-sdk-core';
import { ApiResponse } from '../../../../Modules/Utils/API/Interfaces/IApi';

@Service()
export class FeatureEligibility implements IFeatureEligibility {
    protected readonly _window: Window;
    protected readonly _logger: ILogger;

    constructor() {
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('FeatureEligibility');
    }

    public IsUserEligible = async (propertyName: AppEligibilityPropertyEnum): Promise<boolean> => {
        if (!propertyName) return false;

        const { response } = await this.GetEligibility(propertyName);

        return !!response?.IsEligible || false;
    };

    public GetEligibility = async (
        widgetType: AppEligibilityPropertyEnum,
    ): Promise<ApiResponse<IEligibilityResponseWrapper<IUnifiedCashierEligibilityResponse>>> => {
        const country = this._window.pageContextManager.getGlobalizationData().country;
        const subBrandId = this._window.pageContextManager.getBrandData().subBrandId;

        const getEligibilityRequest: IGetEligibilityRequest = {
            SubBrandId: subBrandId,
            Country: country,
            Type: widgetType,
        };
        return UserApi.GetEligibility(getEligibilityRequest);
    };
}

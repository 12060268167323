import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { IClientsFrameworkOpenServiceProductService } from './interfaces';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WindowToken } from '../../../injection-tokens';

@Service()
export class ClientsFrameworkOpenServiceProductService
    implements IClientsFrameworkOpenServiceProductService
{
    private readonly _logger: ILogger;
    private readonly _window: Window;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger(
            'ClientsFrameworkOpenServiceProductService',
        );
        this._window = Container.get(WindowToken);
    }

    public clientAutoLogin = async (
        subBrandId,
        productPackageId,
        additionalActivationParameters,
        width,
        height,
        onClose,
        linkId?,
        target?,
        openInMobileContainer?,
        showCloseButton?,
        paramsToAdd?,
        getUrl?,
        functionToInvoke?,
    ) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready;
        return this._window.PF.Web.ClientsFramework.OpenServiceProductPackage.clientAutoLogin(
            subBrandId,
            productPackageId,
            additionalActivationParameters,
            width,
            height,
            onClose,
            linkId,
            target,
            openInMobileContainer,
            showCloseButton,
            paramsToAdd,
            getUrl,
            functionToInvoke,
        );
    };
}

import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { IClientsFrameworkInitService } from './interfaces';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WindowToken } from '../../../injection-tokens';

@Service()
export class ClientsFrameworkInitService implements IClientsFrameworkInitService {
    private readonly _logger: ILogger;
    private readonly _window: Window;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ClientsFrameworkInitService');
        this._window = Container.get(WindowToken);
    }

    public refreshDataStore = async (forceSet: boolean, onSuccess: Function): Promise<void> => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Init.refreshDataStore(forceSet, onSuccess),
        );
    };

    public setAuthenticatedSessionData = async (
        forceSet: boolean,
        onDone: Function,
    ): Promise<void> => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Init.setAuthenticatedSessionData(forceSet, onDone),
        );
    };

    public RealityCheck = async () => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Init.RealityCheck(),
        );
    };

    public SetLoginNotification = async () => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Init.SetLoginNotification(),
        );
    };
}

import Container from 'typedi';
import {
    FeatureAvailabilityToken,
    GTMDataLayerToken,
    LibraryManagerToken,
    UCFData,
    UserContextToken,
    UserDataStoreDeferredObjectToken,
} from '../../../../../injection-tokens';
import { FeatureAvailability } from '../../../../feature/feature-availability/feature-availability';
import { UserContext } from '../../../../user-context';

export const setClientServices = () => {
    try {
        Container.set(GTMDataLayerToken, window.dataLayer);
        Container.set(UCFData, window.unifiedClientDataProvider);
        Container.set(UserDataStoreDeferredObjectToken, window.userDataStoreDO);
        Container.set(UserContextToken, new UserContext());
        Container.set(FeatureAvailabilityToken, new FeatureAvailability());
        Container.set(LibraryManagerToken, window.libraryManager);
    } catch (err) {
        console.error('setClientServices failed');
    }
};

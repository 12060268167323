import {
    IChatActivityTopicPayload,
    ICustomerRelationsChannel,
    ILogger,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { CustomerRelationsService } from '../../customer-relations/customer.relations.service';
import { ICustomerRelationsRequest } from '../../customer-relations/models/ICustomerRelationsRequest';
import { IMb2Cb } from '../../mb-2-cb/models/IMb2Cb';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class CustomerRelationChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _customerRelationsChannel: ICustomerRelationsChannel;
    private readonly _customerRelationsService: CustomerRelationsService;

    private get _mb2CBPromise(): Promise<IMb2Cb> {
        return LoaderManager.Instance.Mb2CbLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('CustomerRelationChannelSubscriber');
        this._customerRelationsService = Container.get(CustomerRelationsService);
        this._customerRelationsChannel = MessageBroker.getInstance().customerRelations;
        this._customerRelationsChannel.topics.CTA_CustomerRelations_Open.subscribe(
            this.onCustomerRelationsOpen.bind(this),
        );
        this._customerRelationsChannel.topics.chatActivity.subscribe(this.chatActivity.bind(this));
    }

    private onCustomerRelationsOpen = async (data: ICustomerRelationsRequest): Promise<void> => {
        this._customerRelationsService.openCustomerRelations(data);
    };

    private async chatActivity(data: IChatActivityTopicPayload): Promise<void> {
        this._logger.debug(`[chatActivity]] start, data: ${JSON.stringify(data)}`);
        const mb2cb = await this._mb2CBPromise;
        if (mb2cb) {
            await mb2cb.chatActivity(data);
        }
    }
}

import { ClientIntegrationFacadeToken, WindowToken } from '../../injection-tokens';
import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { ILogger, IRouterChannel, ITopicSubscription, MessageBroker } from '@sparkware/uc-sdk-core';
import DocumentHelper from '../../../Modules/Utils/DocumentHelper';
import { FlowsRunner } from '../flows-runner';
import { RouterService } from './router.service';
import { UserareaService } from '../external/userarea';
import { UrlUtils } from '../utils/urlUtils';
import { RouterUtils } from './router.utils';
import { IClientIntegrationFacade } from '../client-integration/interfaces/IClientIntegrationFacade';

@Service()
export class Router {
    private _navigationByCodeSubscription: ITopicSubscription;
    private _changeBrowserURISubscription: ITopicSubscription;
    private readonly _logger: ILogger;
    private readonly _routerService: RouterService;
    private readonly _window: Window;
    private readonly _channel: IRouterChannel;
    private readonly _clientIntegrationFacade: IClientIntegrationFacade;
    private readonly _flowsRunner: FlowsRunner;
    private readonly _userareaService: UserareaService;

    private readonly _urlUtils: UrlUtils;
    private readonly _routerUtils: RouterUtils;

    public get channel(): IRouterChannel {
        return this._channel;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('Router');
        this._logger.debug('Initialising Router module...');
        this._window = Container.get(WindowToken);
        this._routerService = Container.get(RouterService);
        this._channel = MessageBroker.getInstance().router;
        this._clientIntegrationFacade = Container.get(ClientIntegrationFacadeToken);
        this._flowsRunner = Container.get(FlowsRunner);
        this._userareaService = Container.get(UserareaService);
        this._routerUtils = Container.get(RouterUtils);
        this._urlUtils = Container.get(UrlUtils);
        this.Enable();
        this.CheckForDeepLinkHash();
    }

    public HandleFirstHit() {
        const subHandler = () => {
            setTimeout(() => {
                this._routerService.NavigateTo(
                    this._urlUtils.getRelativeURL(this._routerUtils.URI),
                );
            }, 0);
        };

        DocumentHelper.onDomReady(subHandler);
    }

    public Enable() {
        this._toggleURLHandler(false);
    }

    public Disable() {
        this._toggleURLHandler(true);
    }

    private _toggleURLHandler(isRemoving: boolean): void {
        const { changeBrowserURISubscribe } = this._clientIntegrationFacade;
        const eventListenerFunc = isRemoving
            ? this._window.removeEventListener
            : this._window.addEventListener;

        this._logger.debug(
            `${isRemoving ? 'Unregistering' : 'Registering'} navigation click events...`,
        );
        eventListenerFunc('click', this._routerService.handleClickEvents, true);

        this._logger.debug(
            `${isRemoving ? 'Unregistering' : 'Registering'} navigation popstate events...`,
        );
        eventListenerFunc('popstate', this._routerService.handlePopStateEvents, true);

        this._logger.debug(
            `${isRemoving ? 'Unregistering' : 'Registering'} navigation by code events...`,
        );

        if (isRemoving) {
            if (this._navigationByCodeSubscription)
                this._navigationByCodeSubscription.unsubscribe();
        } else {
            this._navigationByCodeSubscription = this._channel.topics.NavigateByCode.subscribe(
                this._routerService.handleCodeNavigation,
            );
        }

        this._logger.debug(
            `${isRemoving ? 'Unregistering' : 'Registering'} change browser uri events...`,
        );

        if (isRemoving) {
            if (this._changeBrowserURISubscription)
                this._changeBrowserURISubscription.unsubscribe();
        } else
            this._changeBrowserURISubscription = changeBrowserURISubscribe(
                this._routerService.handleChangeBrowserURI,
            );
    }

    private CheckForDeepLinkHash(): void {
        const deepLinkHash = this._urlUtils.getDeeplinkHash();
        if (deepLinkHash) {
            this._userareaService.executeOnload(async () => {
                await this._flowsRunner.run(deepLinkHash);
                const newUrl = this._urlUtils.removeParamsFromQuery(
                    this._urlUtils.getCurrentUrl(),
                    'deeplink',
                );

                this._urlUtils.historyReplaceState(this._window.document.title, newUrl);
            });
        }
    }
}

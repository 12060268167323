import { MessageBroker } from '@sparkware/uc-sdk-core';
import Container from 'typedi';
import { ClientProvider } from '../../../../models/enums/general-enums';
import { RouterUtils } from '../../../router/router.utils';
import { GTMTracking } from '../../../tracking/models/gtmTracking';
import { TrackingUtilsService } from '../../../tracking/tracking-utils.service';
import { Utils } from '../../../utils';
import { UrlUtils } from '../../../utils/urlUtils';

// todo maoz - check the name?
export const getClientInitObject = () => {
    const { name } = window.pageContextManager.getClientProviderData();
    switch (name) {
        case ClientProvider.Kambi: {
            return getKambiClientInitObject();
        }
        case ClientProvider.Poker: {
            return getPokerClientInitObject();
        }
        case ClientProvider.Spectate: {
            return getSpectateClientInitObject();
        }
    }
};

export const getPokerClientInitObject = () => {
    const routerUtils = Container.get(RouterUtils);
    const urlUtils = Container.get(UrlUtils);
    return {
        active: routerUtils.isHostedClientEnabledOnURL(routerUtils.URI),
        messageBrokerChannels: MessageBroker.getInstance(),
        navigation: decodeURIComponent(
            urlUtils.getRelativeURL(routerUtils.URI, {
                removeLanguage: true,
                removeBaseUrl: true,
            }),
        ),
    };
};

export const getSpectateClientInitObject = () => {
    const routerUtils = Container.get(RouterUtils);
    const urlUtils = Container.get(UrlUtils);
    const gtmTrackingProvider = Container.get(GTMTracking);
    return {
        active: routerUtils.isHostedClientEnabledOnURL(routerUtils.URI),
        messageBrokerChannels: MessageBroker.getInstance(),
        GTMDataLayer: gtmTrackingProvider.GTMDataLayer,
        navigation: decodeURIComponent(
            urlUtils.getRelativeURL(routerUtils.URI, {
                removeLanguage: true,
                removeBaseUrl: true,
            }),
        ),
    };
};

const getKambiClientInitObject = () => {
    const trackingUtilsService = Container.get(TrackingUtilsService);
    const utils = Container.get(Utils);
    const routerUtils = Container.get(RouterUtils);
    const reportingParameters = {
        ...trackingUtilsService.getReportingParameters(),
        userMode: utils.getUserMode(),
    };

    const contentLanguage = window.pageContextManager.getLocalizationData().contentLangId;

    return {
        active: routerUtils.isHostedClientEnabledOnURL(routerUtils.URI),
        messageBrokerChannels: MessageBroker.getInstance(),
        nativeUXVersion: 0, //TODO,this._nativeUXVersion,
        contentLanguage: contentLanguage,
        reportingParameters,
    };
};

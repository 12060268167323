import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { ICommonService } from './interfaces';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WindowToken } from '../../../injection-tokens';

@Service()
export class CommonService implements ICommonService {
    private readonly _logger: ILogger;
    private readonly _window: Window;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('CommonService');
        this._window = Container.get(WindowToken);
    }

    public InjectWrappingDivWithCloseButton = async (
        src,
        width?,
        height?,
        border?,
        onClose?,
        productPackageId?,
        classToAdd?,
    ) =>
        this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.Common.InjectWrappingDivWithCloseButton(
                src,
                width,
                height,
                border,
                onClose,
                productPackageId,
                classToAdd,
            ),
        );

    public sendAnalytics = async (eventData: any) =>
        this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.Common.sendAnalytics(eventData),
        );

    public CloseWrapDiv = async (obj?: any) =>
        this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.Common.CloseWrapDiv(obj),
        );

    public OpenMobileContainerDiv = async (
        src: string,
        closeBtnRequired: boolean,
        onClose: Function,
    ) =>
        this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.Common.OpenMobileContainerDiv(src, closeBtnRequired, onClose),
        );

    public InjectWrappingDiv = async (
        src: string,
        width: number,
        height: number,
        border: boolean,
        isOmgMsg: boolean,
        classToAdd?: string,
    ) =>
        this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.Common.InjectWrappingDiv(
                src,
                width,
                height,
                border,
                isOmgMsg,
                classToAdd,
            ),
        );

    public openMobileAppWithFallback = async (
        url: string,
        fallbackURLAndroid: string,
        fallbackURLIOS: string,
    ) =>
        this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.Common.openMobileAppWithFallback(
                url,
                fallbackURLAndroid,
                fallbackURLIOS,
            ),
        );
}

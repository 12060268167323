import { AxiosRequestConfig } from 'axios';
import { ApiResponse, HTTPVerb } from '../Modules/Utils/API/Interfaces/IApi';
import { ApiManager } from '../Modules/Utils/API/ApiManager';

enum APIConstants {
    GetNativeBrandProduct = '/api/native/getNativeBrandProduct/',
    GetAppUpgradeRequiredData = '/api/native/app-upgrade-required/',
}

export interface AppUpgradeRequiredData {
    MinimumAppVersionIOS: string;
    MinimumAppVersionAndroid: string;
    AppDownloadLinkIOS: string;
    AppDownloadLinkAndroid: string;
}

export default class NativeApi {
    public static GetNativeBrandProduct = async (params): Promise<ApiResponse> => {
        const requestOptions: AxiosRequestConfig = {
            url: APIConstants.GetNativeBrandProduct,
            params,
            method: HTTPVerb.GET,
        };

        return ApiManager.getAPIInstance().request(requestOptions);
    };

    public static GetAppUpgradeRequired = async (): Promise<
        ApiResponse<AppUpgradeRequiredData>
    > => {
        const requestOptions: AxiosRequestConfig = {
            url: APIConstants.GetAppUpgradeRequiredData,
            method: HTTPVerb.GET,
        };

        return ApiManager.getAPIInstance().request(requestOptions);
    };
}

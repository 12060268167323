import Container, { Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { IPostMessageHandler, IPostMessageService } from '../post-message';
import {
    ActionID,
    AppIdentifiers,
    AreaType,
    TriggerType,
    IPerformActionTopicPayload,
    ILogger,
} from '@sparkware/uc-sdk-core';
import { IActionHandlerFactory } from '../action-handler/models/IActionHandlerFactory';
import { Utils } from '../utils';
import { LoaderManager } from '../../loaders/LoaderManager';
import { WindowToken } from '../../injection-tokens';

@Service()
export class Registration {
    private readonly _window: Window;
    private readonly _logger: ILogger;
    private readonly _utils: Utils;

    private get _actionHandlerFactoryPromise(): Promise<IActionHandlerFactory> {
        return LoaderManager.Instance.ActionHandlerFactoryLoader.Instance;
    }

    private get _registrationHandlerPromise(): Promise<IPostMessageHandler> {
        return LoaderManager.Instance.RegistrationHandlerLoader.Instance;
    }

    private get _postMessageServicePromise(): Promise<IPostMessageService> {
        return LoaderManager.Instance.PostMessageServiceLoader.Instance;
    }

    constructor() {
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('Registration');
        void this._registerPostMessageHandler();
        this._utils = Container.get(Utils);
    }

    public _registerPostMessageHandler = async (): Promise<void> => {
        const handler = await this._registrationHandlerPromise;
        this._logger.debug(`Registering ${handler.name}...`);
        const postMessageService = await this._postMessageServicePromise;
        postMessageService?.addHandler(handler);
    };

    public openRegistration = async (): Promise<void> => {
        const { clientVersion } = this._window.pageContextManager.getDeviceData();
        const openRegister: IPerformActionTopicPayload = {
            actionID: ActionID.register,
            correlationID: this._utils.getCorrelationId(),
            launchInfo: {
                businessCorrelationID: this._utils.generateCorrelationID(),
                channel: {
                    area: AreaType.button,
                    source: 'Unified Client',
                    element: 'Default',
                },
                sourceAppID: AppIdentifiers.UnifiedClient,
                sourceAppVersion: '0.0.0',
                appSpecificParameters: {},
                trigger: TriggerType.userSelection,
                clientVersion: clientVersion,
            },
            actionData: {},
        };
        const actionHandlerFactory = await this._actionHandlerFactoryPromise;
        await actionHandlerFactory.performAction(openRegister);
    };
}

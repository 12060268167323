import Container, { Service } from 'typedi';
import { WindowToken } from '../../../injection-tokens';
import { IMiniCashier } from '../../mini-cashier/models';
import { IPostMessageService } from '../../post-message';
import { ISessionManager } from '../../session-manager/interfaces/ISessionManager';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { UIService } from '../../ui';
import { UserInteraction } from '../../user-interaction';
import { ClientTracking } from '../../tracking/models/clientTracking';
import { IPreloaderManager } from '../../ui/preloader/models/interfaces/IPreloaderManager';
import { IMb2Cb } from '../../mb-2-cb/models/IMb2Cb';
import { IAppLauncher } from '../../app-launcher/models';
import { CloseReason } from '../../../models/enums/general-enums';

@Service()
export class WindowEventsSubscriber {
    private readonly _window: Window;
    private readonly _uiService: UIService;
    private readonly _userInteraction: UserInteraction;
    private readonly _clientTracking: ClientTracking;

    protected get _miniCashierPromise(): Promise<IMiniCashier> {
        return LoaderManager.Instance.MiniCashierLoader.Instance;
    }

    private get _sessionManagerPromise(): Promise<ISessionManager> {
        return LoaderManager.Instance.SessionManagerLoader.Instance;
    }

    private get _postMessageServicePromise(): Promise<IPostMessageService> {
        return LoaderManager.Instance.PostMessageServiceLoader.Instance;
    }

    private get _preloaderManagerPromise(): Promise<IPreloaderManager> {
        return LoaderManager.Instance.PreloaderManagerLoader.Instance;
    }

    private get _mb2CBPromise(): Promise<IMb2Cb> {
        return LoaderManager.Instance.Mb2CbLoader.Instance;
    }

    private get _appLauncherPromise(): Promise<IAppLauncher> {
        return LoaderManager.Instance.AppLauncherLoader.Instance;
    }

    constructor() {
        this._window = Container.get(WindowToken);
        this._uiService = Container.get(UIService);
        this._userInteraction = Container.get(UserInteraction);
        this._clientTracking = Container.get(ClientTracking);
        this._window.addEventListener('load', this.onWindowLoad);
        this._window.addEventListener('message', this.onWindowMessage, false);
        this._window.addEventListener(
            'preloader-finished-event',
            this.onWindowPreLoaderFinishedEvent,
        );
        this._window.addEventListener('click', this.onWindowClick.bind(this), true);
        this._window.addEventListener('scroll', this.onWindowScroll);
        this._window.addEventListener('resize', this.onWindowResize);
        this._window.addEventListener('beforeunload', this.onBeforeUnload);
        this._window.addEventListener('pagehide', this.onPageHide);

        const hamburgerButton = this._window.document.querySelector('.aChamburger');
        if (hamburgerButton !== null) {
            hamburgerButton.addEventListener('click', this.onBurgerMenuClicked);
        }

        const exitOffCanvas = this._window.document.querySelector('.exit-off-canvas');
        if (exitOffCanvas !== null) {
            exitOffCanvas.addEventListener('click', this.onExitOffCanvasClicked);
        }
    }

    private onWindowLoad = async (): Promise<void> => {
        const miniCashier = await this._miniCashierPromise;
        miniCashier?.getMiniCashierDetails();
    };

    private onWindowMessage = async (event: any): Promise<void> => {
        const postMessageService = await this._postMessageServicePromise;
        postMessageService?.postMessageHandler(event);
    };

    private onWindowPreLoaderFinishedEvent = async (): Promise<void> => {
        const preloaderManager = await this._preloaderManagerPromise;
        preloaderManager?.preloaderEventsHandler();
    };

    private onWindowClick = async (event: MouseEvent): Promise<void> => {
        this._clientTracking.onHandleClickEvents(event);

        const sessionManager = await this._sessionManagerPromise;
        sessionManager?.onUserInteraction();
    };

    private onWindowScroll = async (): Promise<void> => {
        const sessionManager = await this._sessionManagerPromise;
        sessionManager?.onUserInteraction();
    };

    private onWindowResize = async (): Promise<void> => {
        this._uiService.setDeviceTypeClass();
    };

    private onBurgerMenuClicked = async (): Promise<void> => {
        this._userInteraction.burgerMenuClicked();
    };

    private onExitOffCanvasClicked = async (): Promise<void> => {
        this._userInteraction.exitOffCanvasClicked();
    };

    private onBeforeUnload = async (event: MouseEvent): Promise<void> => {
        const mb2cb = await this._mb2CBPromise;
        await mb2cb?.onBeforeUnload(event);
    };

    private onPageHide = async (): Promise<void> => {
        const appLauncher = await this._appLauncherPromise;
        await appLauncher?.CloseOpenedApps(CloseReason.automaticClosure);
    };
}

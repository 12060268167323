import { IModuleLoader } from './IModuleLoader';
import { Lock } from '../modules/utils/lockUtil';
import Container, { Token } from 'typedi';
import { LoggerProvider } from '../modules/logger';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WindowToken } from '../injection-tokens';

export abstract class BaseModuleLoader<T> implements IModuleLoader<T> {
    protected readonly _logger: ILogger;
    protected readonly _window: Window;

    public get Instance(): Promise<T> {
        return this._tryLoadModule();
    }

    protected constructor(moduleName: string) {
        this._logger = Container.get(LoggerProvider).getLogger(`ModuleLoader - ${moduleName}`);
        this._window = Container.get(WindowToken);
    }

    protected _canUse = async (): Promise<boolean> => true;

    protected _getModuleLock: () => Lock = () => {
        throw Error('Not implemented.');
    };
    protected _getToken: () => Token<T> = () => {
        throw Error('Not implemented.');
    };
    protected _createModuleInstance: () => Promise<T> = () => {
        throw Error('Not implemented.');
    };
    private _tryLoadModule = async (): Promise<T> => {
        const lock = this._getModuleLock();
        const token = this._getToken();

        const isEnabled = await this._canUse();
        this._logger.debug(`_tryLoadModule: module status: ${isEnabled ? 'Enabled' : 'Disabled'}`);

        if (!isEnabled) return null;

        await lock.acquireLock();

        try {
            if (Container.has(token)) {
                return Container.get(token);
            } else {
                this._logger.debug(`_tryLoadModule: Attempt to load dynamic chunk.`);

                const moduleInstance = await this._createModuleInstance();
                Container.set(token, moduleInstance);

                return moduleInstance;
            }
        } catch (e) {
            this._logger.error(`_tryLoadModule: Error loading dynamic chunk.`, e);
            return null;
        } finally {
            this._logger.debug(`_tryLoadModule: lock was released`);

            lock.releaseLock();
        }
    };
}

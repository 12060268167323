import Container from 'typedi';
import { WindowToken } from '../../src/injection-tokens';

/**
 * Helper functions for html document
 */
export default class DocumentHelper {
    /**
     * Function to set a callback for when document 'DomContentLoaded' event is triggered
     * @param callBack
     */
    public static onDomReady = (callBack: () => void) => {
        const window = Container.get(WindowToken);

        if (window.document.readyState === 'loading')
            window.document.addEventListener('DOMContentLoaded', callBack);
        else callBack();
    };

    /**
     * Function to set a callback for when window 'load' event is triggered
     * @param callBack
     */
    public static onWindowReady = (callBack: () => void) => {
        const window = Container.get(WindowToken);

        if (window.document.readyState === 'complete') callBack();
        else window.addEventListener('load', callBack);
    };

    /**
     * Function to set a callback for when an event is triggered
     * @param eventName name of the event to be triggered
     * @param callback function to run when triggered
     */
    public static addEventListener(eventName: string, callback: (event: any) => void) {
        const window = Container.get(WindowToken);

        window.addEventListener(eventName, callback);
    }
}

import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import {
    INavigationChannel,
    IOpenAppData,
    MessageBroker,
    IRedirectOrNativeOpenExternalUrl,
    ILogger,
} from '@sparkware/uc-sdk-core';
import { Navigation } from '../../navigation';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { PendingService } from '../../pending/pending.service';
import { IMb2Cb } from '../../mb-2-cb/models/IMb2Cb';
import { IAppLauncher } from '../../app-launcher/models';
import { IUpdateAppTopicPayload } from '@sparkware/uc-sdk-core/lib/message-broker/channels/navigation/navigation.channel.interfaces';
import { Utils } from '../../utils';

@Service()
export class NavigationChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _navigationChannel: INavigationChannel;
    private readonly _navigation: Navigation;
    private readonly _pendingService: PendingService;
    private readonly _utils: Utils;

    private get _mb2CBPromise(): Promise<IMb2Cb> {
        return LoaderManager.Instance.Mb2CbLoader.Instance;
    }

    private get _appLauncherPromise(): Promise<IAppLauncher> {
        return LoaderManager.Instance.AppLauncherLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('NavigationChannelSubscriber');
        this._navigation = Container.get(Navigation);
        this._utils = Container.get(Utils);
        this._pendingService = Container.get(PendingService);
        this._navigationChannel = MessageBroker.getInstance().navigation;
        this._navigationChannel.topics.openApp.subscribe(this.onOpenApp.bind(this));
        this._navigationChannel.topics.openAppPending.subscribe(this.onOpenAppPending.bind(this));
        this._navigationChannel.topics.redirectOrNativeOpenExternalUrl.subscribe(
            this.onRedirectOrNativeOpenExternalUrl.bind(this),
        );
        this._navigationChannel.topics.overlayClicked.subscribe(this.onOverlayClicked.bind(this));
        const isAppWrapper = this._utils.isAppWrapper();
        const isNativeSdk = this._utils.isNativeSDK();
        if (isNativeSdk && !isAppWrapper) {
            this._navigationChannel.topics.updateApp.registerResolver(this.onUpdateApp.bind(this));
        }
    }

    private async onOpenApp(data: IOpenAppData): Promise<void> {
        this._logger.debug(`[onOpenApp]] start, data: ${JSON.stringify(data)}`);
        const mb2cb = await this._mb2CBPromise;
        if (mb2cb) {
            await mb2cb.onOpenApp(data);
        }
        await this._navigation.onOpenApp(data);
    }

    private async onOpenAppPending(): Promise<void> {
        this._logger.debug('[onOpenAppPending]] start');
        await this._pendingService.showPending();
    }

    private async onRedirectOrNativeOpenExternalUrl(
        data: IRedirectOrNativeOpenExternalUrl,
    ): Promise<void> {
        this._logger.debug(
            `[onRedirectOrNativeOpenExternalUrl]] start, data: ${JSON.stringify(data)}`,
        );
        await this._navigation.redirectOrNativeOpenExternalUrl(data);
    }

    private async onOverlayClicked(): Promise<void> {
        const appLauncher = await this._appLauncherPromise;
        await appLauncher?.onOverlayClicked();
    }

    private async onUpdateApp(data: IUpdateAppTopicPayload): Promise<void> {
        const appLauncher = await this._appLauncherPromise;
        await appLauncher?.onUpdateApp(data);
    }
}

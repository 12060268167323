import Container, { Service } from 'typedi';
import { ClientInit } from './client-init';
import { SessionStorageToken } from '../../../injection-tokens';
import { ILoginSuccessData } from '@sparkware/uc-sdk-core';
import { StorageItemEnum } from '../../../models/enums/storage-enums';

@Service()
export class SpectateClientInit extends ClientInit {
    constructor() {
        super();
        this._clientIntegrationFacade.playerPreferencesSubscribe((data: string) => {
            const sessionStorageWrapper = Container.get(SessionStorageToken);
            sessionStorageWrapper.setItem(StorageItemEnum.OddsFormat, data);
        });
    }

    protected override async onLoginSuccess(data: ILoginSuccessData) {
        const isLoginPublish = !data || data.IsClientAuthenticationRequired === true;
        await super.onLoginSuccess(data, isLoginPublish);
    }
}

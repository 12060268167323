import { ClientIntegrationFacadeToken, FeatureAvailabilityToken } from '../../../injection-tokens';
import { ClientSettings } from './client-settings';
import Container from 'typedi';
import { KambiClientIntegrationFacade } from '../kambi-client-integration.facade';
import { IKambiClientSettings } from '@sparkware/uc-sdk-core';
import { Features } from '../../../models/enums/general-enums';
import { IFeatureAvailability } from '../../feature/feature-availability/feature-availability-interface';

export class KambiClientSettings extends ClientSettings {
    private _featureAvailability: IFeatureAvailability;

    constructor() {
        super();
        this._featureAvailability = Container.get(FeatureAvailabilityToken);
    }

    public getClientSettings = (): IKambiClientSettings => {
        this.getClientSettingsPerformance();
        const { isMobile } = this._window.pageContextManager.getDeviceData();
        const { langIso3 } = this._window.pageContextManager.getLocalizationData();
        const germanyRegulationEnabled = this._featureAvailability.IsFeatureEnabled(
            Features.GERMANY_REGULATION,
        );
        const newBannerEnabled = this._featureAvailability.IsFeatureEnabled(Features.NEW_BANNER);
        return {
            virtualSportEnabled: false,
            virtualSportLink: '',
            GermanyRegulationEnabled: germanyRegulationEnabled,
            NewBannerEnabled: newBannerEnabled,
            deviceType: isMobile ? 'mobile' : 'desktop',
            languageCode: langIso3,
        };
    };

    protected registerTokens() {
        super.registerTokens();
        Container.set(ClientIntegrationFacadeToken, new KambiClientIntegrationFacade());
    }
}

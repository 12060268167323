import { Lock } from '../utils/lockUtil';
import { Container, Service, Token } from 'typedi';
import { IMiniCashier } from './models';
import { Features } from '../../models/enums/general-enums';
import { FeatureAvailabilityToken } from '../../injection-tokens';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
import { IFeatureAvailability } from '../feature/feature-availability/feature-availability-interface';

const moduleLock = new Lock();

export const MiniCashierToken = new Token<IMiniCashier>('MiniCashier');
@Service()
export class MiniCashierLoader extends BaseModuleLoader<IMiniCashier> {
    private _featureAvailability: IFeatureAvailability;
    constructor() {
        super('MiniCashierLoader');
        this._featureAvailability = Container.get(FeatureAvailabilityToken);
    }

    protected _createModuleInstance = async (): Promise<IMiniCashier> => {
        const { MiniCashier } = await import(
            /* webpackChunkName: "mini-cashier/mini-cashier" */
            './mini-cashier'
        );

        return new MiniCashier();
    };

    protected override _canUse: () => Promise<boolean> = async () => {
        return this._featureAvailability.IsFeatureEnabled(Features.MINI_CASHIER);
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IMiniCashier> => MiniCashierToken;
}

import Container from 'typedi';
import {
    AppIdentifiers,
    HostedClientLoadedEvent,
    IAuthenticateTopicData,
    IClientSettings,
    ILoginSuccessData,
    ILogoutSuccessData,
    IPlayerSegmentationData,
    ITopicSubscription,
    MessageBroker,
    OfferingNames,
    UserMode,
    IKambiClientSettings,
    IdentityChannel,
    IUiChannel,
    ILogger,
} from '@sparkware/uc-sdk-core';
import {
    ClientIntegrationFacadeToken,
    ClientSettingsToken,
    FeatureAvailabilityToken,
    LibraryManagerToken,
    UserContextToken,
    UserDataStoreDeferredObjectToken,
    WindowToken,
} from '../../../injection-tokens';
import {
    GetTokenErrorCodes,
    PreloaderComponentType,
    PreloaderElasticEvent,
} from '../../../models/enums/general-enums';
import { ClientsFrameworkLogoutService } from '../../external/clients-framework';
import { IPreloaderComponentResponseData } from '../../ui/preloader/models/interfaces/IPreloaderComponentResponseData';
import { StorageItemEnum } from '../../../models/enums/storage-enums';
import TokenApi from '../../../../APIs/TokenApi';
import { LoggerProvider } from '../../logger';
import { LocalSimpleStoreService } from '../../storage/implementations/simple-store';
import { EnvAccessAuthentication } from '../../environment-access-auth';
import { ABTestFeatures, Offering } from '../../action-handler/enums';
import EnvAccessAuthenticationConsts from '../../environment-access-auth/constants/EnvAccessAuthenticationConsts';
import { IClientInit } from './interfaces/IClientInit';
import { IClientIntegrationFacade } from '../interfaces/IClientIntegrationFacade';
import ContextApi from '../../../../APIs/ContextApi';
import { RouterUtils } from '../../router/router.utils';
import { UrlUtils } from '../../utils/urlUtils';
import { AuthenticatedPageContextData } from 'page-context-manager';
import { ISessionUserData } from '../../session-manager/interfaces/ISessionUserData';
import { IAppCatalog } from '../../app-catalog/interfaces';
import { AuthenticationUtils } from '../../authentication/utils/authentication.utils';
import { GlobalSubscriber } from '../../global-subscriber/global-subscriber';
import { Navigation } from '../../navigation';
import { IUserContext } from '../../user-context/user-context-interface';
import { ClientTracking } from '../../tracking/models/clientTracking';
import { Tracking } from '../../tracking/models/tracking';
import { WebVitals } from '../../tracking/web-vitals';
import { IWebPushService } from '../../web-push/models/IWebPushService';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { IPushController } from '../../push/models/IPushController';
import { IOptimizely } from '../../optimizely/interfaces';
import { IPostMessageHandler } from '../../post-message';
import { IBIEventHandler } from '../../bi/models/IBIEventHandler';
import { ISessionManager } from '../../session-manager/interfaces/ISessionManager';
import { ILoyaltyIndication } from '../../loyalty-indication/models/ILoyaltyIndication';
import { ViewInjector } from '../../view-injector';
import { Utils } from '../../utils';
import { NativeUtils } from '../../native/native.utils';
import { UserareaService } from '../../external/userarea';
import { ThemeController } from '../../theme/theme.controller';
import { SmartBannerController } from '../../smart-banner/smart.banner.controller';
import { B2CIntegration } from '../../b2c-integration';
import { WebLogin } from '../../web-login/web.login';
import { SessionState } from '../../session-manager/session-state';
import { GradualMigration } from '../../identity';
import { ILibraryManager } from '../../external/library/ILibraryManager';
import DeferredObject from '../../../../Modules/Utils/DeferredObject';
import { IFeatureAvailability } from '../../feature/feature-availability/feature-availability-interface';
import { IPreloaderManager } from '../../ui/preloader/models/interfaces/IPreloaderManager';
import { ApiResponse } from '../../../../Modules/Utils/API/Interfaces/IApi';
import { ClientSettings } from '../settings/client-settings';

export abstract class ClientInit implements IClientInit {
    private _nativeUXVersion: number;
    private _loginSuccessSubscription: ITopicSubscription;
    // private modules
    private readonly _window: Window;
    private readonly _logger: ILogger;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;
    private readonly _userDataStoreDeferred: DeferredObject<ISessionUserData>;
    private readonly _urlUtils: UrlUtils;
    private readonly _utils: Utils;
    private readonly _clientSettings: ClientSettings;
    private readonly _userareaService: UserareaService;
    private readonly _navigation: Navigation;
    private readonly _routerUtils: RouterUtils;
    private readonly _featureAvailability: IFeatureAvailability;
    private readonly _viewInjector: ViewInjector;
    private readonly _smartBannerController: SmartBannerController;
    private readonly _b2cIntegration: B2CIntegration;
    private readonly _gradualMigration: GradualMigration;
    private readonly _webVitals: WebVitals;
    private readonly _cfLogoutService: ClientsFrameworkLogoutService;
    private readonly _libraryManager: ILibraryManager;
    private readonly _envAccessAuthentication: EnvAccessAuthentication;
    private readonly _authenticationUtils: AuthenticationUtils;
    private readonly _webLogin: WebLogin;
    private readonly _identityChannel: IdentityChannel;
    private readonly _uiChannel: IUiChannel;

    // protected modules
    protected readonly _userContext: IUserContext;
    protected readonly _clientIntegrationFacade: IClientIntegrationFacade;

    private get _webPushServicePromise(): Promise<IWebPushService> {
        return LoaderManager.Instance.WebPushLoader.Instance;
    }

    private get _pushPromise(): Promise<IPushController> {
        return LoaderManager.Instance.PushLoader.Instance;
    }

    private get _optimizelyPromise(): Promise<IOptimizely> {
        return LoaderManager.Instance.OptimizelyLoader.Instance;
    }

    private get _appCatalogPromise(): Promise<IAppCatalog> {
        return LoaderManager.Instance.AppCatalogLoader.Instance;
    }

    private get _pendingHandlerPromise(): Promise<IPostMessageHandler> {
        return LoaderManager.Instance.PendingHandlerLoader.Instance;
    }

    private get _biEventHandlerPromise(): Promise<IBIEventHandler> {
        return LoaderManager.Instance.BIEventHandlerLoader.Instance;
    }

    private get _sessionManagerPromise(): Promise<ISessionManager> {
        return LoaderManager.Instance.SessionManagerLoader.Instance;
    }

    private get _loyaltyIndicationPromise(): Promise<ILoyaltyIndication> {
        return LoaderManager.Instance.LoyaltyIndicationLoader.Instance;
    }

    private get isBossMode(): boolean {
        return this._utils.isBossMode();
    }

    private get _preloaderManagerPromise(): Promise<IPreloaderManager> {
        return LoaderManager.Instance.PreloaderManagerLoader.Instance;
    }

    protected constructor() {
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('ClientInit');
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
        this._userDataStoreDeferred = Container.get(UserDataStoreDeferredObjectToken);
        this._userContext = Container.get(UserContextToken);
        this._urlUtils = Container.get(UrlUtils);
        this._utils = Container.get(Utils);
        this._clientSettings = Container.get(ClientSettingsToken);
        this._clientIntegrationFacade = Container.get(ClientIntegrationFacadeToken);
        this._userareaService = Container.get(UserareaService);
        this._navigation = Container.get(Navigation);
        this._routerUtils = Container.get(RouterUtils);
        this._featureAvailability = Container.get(FeatureAvailabilityToken);
        this._viewInjector = Container.get(ViewInjector);
        this._smartBannerController = Container.get(SmartBannerController);
        this._b2cIntegration = Container.get(B2CIntegration);
        this._gradualMigration = Container.get(GradualMigration);
        this._webVitals = Container.get(WebVitals);
        this._cfLogoutService = Container.get(ClientsFrameworkLogoutService);
        this._libraryManager = Container.get(LibraryManagerToken);
        this._envAccessAuthentication = Container.get(EnvAccessAuthentication);
        this._authenticationUtils = Container.get(AuthenticationUtils);
        Container.get(GlobalSubscriber);
        Promise.allSettled([
            this._optimizelyPromise,
            this._biEventHandlerPromise,
            this._pendingHandlerPromise,
            this._sessionManagerPromise,
        ]).then((results) => {
            this._logger.debug('All required modules promises are settled: ', results);
        });
        this._webLogin = Container.get(WebLogin);
        Container.get(SessionState);
        Container.get(ThemeController);
        this._identityChannel = MessageBroker.getInstance().identity;
        this._identityChannel.topics.loginSuccess.subscribe(this._onLoginSuccess.bind(this));
        this._identityChannel.topics.logoutSuccess.subscribe(this._onLogoutSuccess.bind(this));
        this._uiChannel = MessageBroker.getInstance().ui;
        void this._init();
    }

    public addRestrictedOfferings = async (
        authenticateData: IAuthenticateTopicData,
    ): Promise<void> => {
        const restrictedOfferings =
            this._window.pageContextManager.getUserData().restrictedOfferings;
        if (this._isPokerRestricted(restrictedOfferings)) {
            restrictedOfferings.push(Offering[OfferingNames.Poker]);
        }
        let restrictedApps: string[];
        if (restrictedOfferings) {
            const appCatalog = await this._appCatalogPromise;
            restrictedApps = await appCatalog.getRestrictedApps(restrictedOfferings);
        } else {
            restrictedApps = [];
        }
        if (authenticateData) {
            authenticateData.RestrictedApps = restrictedApps;
        }
    };

    public async onClientLoad(): Promise<void> {
        const preloaderComponentResponseData: IPreloaderComponentResponseData = {
            isLoaded: true,
        };
        this._viewInjector.InjectClient();
        const preloaderManager = await this._preloaderManagerPromise;
        preloaderManager?.onComponentFinished(
            PreloaderComponentType.Client,
            preloaderComponentResponseData,
            PreloaderElasticEvent.ClientFinished,
        );
        this.subscribeToMessageBroker();
        this._webLogin.Init();
        await this._navigation.HandleDeeplinks(this._routerUtils.URI);
    }

    protected _messageBrokerSubscription = (): void => {
        const { playerSegmentationPublish } = this._clientIntegrationFacade;

        if (this._utils.getUserMode() === UserMode.Authenticated) {
            playerSegmentationPublish();
            this._b2cIntegration.playerSegmentationPublish();
        }
    };

    protected subscribeToMessageBroker(): void {
        const { ReadyToConnect } = this._clientIntegrationFacade;
        if (ReadyToConnect.resolved) {
            this._messageBrokerSubscription();
        } else {
            ReadyToConnect.promise.then((ready) => {
                if (!ready) {
                    //possible deletion
                    this._logger.error(
                        "onClientLoad: _messageBrokerSubscription won't be called since the client is not able to communicate through MB events.",
                    );
                }
            });
        }
    }

    protected async onLoginSuccess(
        data: ILoginSuccessData,
        isLoginPublish: boolean = true,
    ): Promise<void> {
        this._gradualMigration.processMigrationData(data.SportMigrationData);
        const { loginPublish, playerSegmentationPublish } = this._clientIntegrationFacade;
        if (isLoginPublish) {
            const { AuthenticateData } = data || {};
            await this.addRestrictedOfferings(AuthenticateData);
            loginPublish(AuthenticateData);
        }
        playerSegmentationPublish();
        this._b2cIntegration.playerSegmentationPublish();
    }

    protected async onLogoutSuccess(data: ILogoutSuccessData): Promise<void> {
        const { logoutPublish, playerSegmentationPublish } = this._clientIntegrationFacade;
        if (!this.isBossMode) {
            const { CID, Reason } = data || {};
            logoutPublish({ CID, Reason });
            playerSegmentationPublish();

            this._b2cIntegration.playerSegmentationPublish();
        } else this._localSimpleStoreService.remove(StorageItemEnum.IsBossMode);
        this._authenticationUtils.removeAuthorizationData();
        this._registerToLoginSuccessEvents();
    }

    protected async _clientLoginSuccessBossMode(): Promise<void> {
        if (!this.isBossMode) return;
        await this._cfLogoutService.doLogoutWithoutRefresh('', false);
    }

    private async _init(): Promise<void> {
        void this._nativeOnUserAreaLoaded();
        this._registerSmartBanner();
        this._initNativeVersionParam();
        this._initNativeOsParams();
        this._initNativeMBRollout();
        this._registerToLoginSuccessEvents();
        Container.get(Tracking);
        this._webVitals.registerHandlers();
        Container.get(ClientTracking);
        await this._webPushServicePromise;
        const { tokenSubscribe } = this._clientIntegrationFacade;
        tokenSubscribe(this._tokenSubscriber.bind(this));
        void this.registerClientLibraryReadyListener();
        void this._tryAddEnvironmentAccessAuthentication();
        this.registerPushOnRefresh();
        this._logger.debug('ClientInit initiated successfully');
    }

    private _nativeOnUserAreaLoaded = async () => {
        if (this._utils.findIfIsNative()) {
            await this._userareaService.executeOnload(() => {
                this._uiChannel.topics.contentReady.publish({ publisher: 'client-init' }, {});
                NativeUtils.checkAppUpgradeRequired();
            });
        }
    };

    private _registerSmartBanner(): void {
        this._smartBannerController.registerSmartBannerService();
    }

    private _initNativeVersionParam = (): void => {
        const qsVersion = new URL(location.href).searchParams.get('nativeUXVersion');
        const localStorageNativeUXVersion = this._localSimpleStoreService.get('nativeUXVersion');

        if (
            !localStorageNativeUXVersion ||
            (qsVersion && Number(qsVersion) > Number(localStorageNativeUXVersion))
        ) {
            const nativeUXVersion = qsVersion;

            if (nativeUXVersion) {
                this._nativeUXVersion = parseInt(nativeUXVersion);
                this._localSimpleStoreService.set(
                    'nativeUXVersion',
                    this._nativeUXVersion.toString(),
                );
            }
        } else {
            this._nativeUXVersion = parseInt(this._localSimpleStoreService.get('nativeUXVersion'));
        }

        this._window.__UAA_STATE__.userContext.nativeUXVersion = this._nativeUXVersion;
    };

    private _initNativeOsParams(): void {
        const currentSearchParams = new URL(location.href).searchParams;
        const nativeOS = currentSearchParams.get('NativeOS');
        const nativeOSVersion = currentSearchParams.get('NativeOSVersion');
        if (nativeOS) {
            this._localSimpleStoreService.set('NativeOS', nativeOS.toString());
        }
        if (nativeOSVersion) {
            this._localSimpleStoreService.set('NativeOSVersion', nativeOSVersion.toString());
        }
    }

    private _initNativeMBRollout(): void {
        const currentSearchParams = new URL(location.href).searchParams;
        const mbRollout = currentSearchParams.get('NativeMBRollout');
        if (mbRollout) {
            this._localSimpleStoreService.set('NativeMBRollout', mbRollout);
        }
    }

    private _onLoginSuccess = async (data: ILoginSuccessData): Promise<void> => {
        await this.onLoginSuccess(data);
    };

    private _onLogoutSuccess = async (data: ILogoutSuccessData): Promise<void> => {
        await this.onLogoutSuccess(data);
    };

    private async registerClientLibraryReadyListener(): Promise<void> {
        const { ReadyToConnect } = this._clientIntegrationFacade;
        const event: HostedClientLoadedEvent<IClientSettings | IKambiClientSettings> =
            await this._libraryManager.ClientLibrary.ready;
        const preClientInitPromises: [
            Promise<ApiResponse<AuthenticatedPageContextData, any>>,
            Promise<any>,
            Promise<boolean>,
            Promise<ISessionUserData>,
        ] = [
            ContextApi.GetAuthenticatedContextData(),
            TokenApi.GetToken(),
            ReadyToConnect.resolved ? Promise.resolve(true) : ReadyToConnect.promise, // segmentation
            this._userDataStoreDeferred.promise,
        ];

        try {
            const [authenticatedContextDataResponse, tokenResponse] =
                await Promise.all(preClientInitPromises);
            let authenticateData: IAuthenticateTopicData;
            let playerSegmentationData: IPlayerSegmentationData;
            const { response, errorResponse } = authenticatedContextDataResponse;
            //if authenticated
            if (!errorResponse && tokenResponse?.Token) {
                playerSegmentationData = this._clientIntegrationFacade.getPlayerSegmentation();
                authenticateData = this._getAuthenticateTopicData(response, tokenResponse?.Token);
                await this.addRestrictedOfferings(authenticateData);
            } else {
                playerSegmentationData =
                    this._clientIntegrationFacade.getPlayerSegmentationInitial();
            }
            event.init({
                clientSettings: this._clientSettings?.getClientSettings(),
                messageBrokerChannels: MessageBroker.getInstance(),
                authenticate: authenticateData,
                segmentation: playerSegmentationData,
                navigation: decodeURIComponent(
                    this._urlUtils.getRelativeURL(this._routerUtils.URI, {
                        removeLanguage: true,
                        removeBaseUrl: true,
                    }),
                ),
            });
            await this.onClientLoad();
        } catch (error) {
            this._logger.error(`[registerClientLibraryReadyListener] failed: ${error} `);
            if (event && typeof event.init === 'function') {
                event?.init({
                    clientSettings: this._clientSettings?.getClientSettings(),
                    messageBrokerChannels: MessageBroker.getInstance(),
                    segmentation: this._clientIntegrationFacade.getPlayerSegmentationInitial(),
                    navigation: decodeURIComponent(
                        this._urlUtils.getRelativeURL(this._routerUtils.URI, {
                            removeLanguage: true,
                            removeBaseUrl: true,
                        }),
                    ),
                });
            }
            await this.onClientLoad();
        }
    }

    private _tryAddEnvironmentAccessAuthentication = async (): Promise<void> => {
        const env = this._window.pageContextManager.getEnvironmentData().environment;
        const { protectedEnvironments } = EnvAccessAuthenticationConsts;
        if (protectedEnvironments.some((i) => i == env?.toLowerCase())) {
            await this._envAccessAuthentication.init();
        }
    };

    private registerPushOnRefresh(): void {
        this._userDataStoreDeferred.promise.then(async () => {
            if (this._userContext.IsAuthenticated) {
                this._loginSuccessSubscription?.unsubscribe();
                await this._registerPush();
                await this._clientLoginSuccessBossMode();
            }
        });
    }

    private async _registerPush(): Promise<void> {
        const pushModule = await this._pushPromise;
        const isClientMode = this._urlUtils.isClientMode();
        if (!this.isBossMode && !isClientMode) {
            const { init } = await this._loyaltyIndicationPromise;
            const {
                onSubscribeTriple8,
                onFailTriple8Subscribe,
                subscribeToPushUpdates,
                registerPushUpdates,
            } = pushModule;

            subscribeToPushUpdates({
                name: 'Triple8',
                onSubscribed: (data) => {
                    onSubscribeTriple8(data);
                },
                onSubscribedFailed: onFailTriple8Subscribe,
            });

            subscribeToPushUpdates({
                name: 'LoyaltyIndication',
                onSubscribed: (data) => {
                    init(data);
                },
            });

            registerPushUpdates();
        }
    }

    private _getAuthenticateTopicData(
        authenticatedPageContextData: AuthenticatedPageContextData,
        token: string,
    ): IAuthenticateTopicData {
        return {
            CID: authenticatedPageContextData.user.cid,
            Token: token,
            HasKambiBets: authenticatedPageContextData.user.hasKambiBets,
            BatchNumber: authenticatedPageContextData.user.batchNumber,
            RestrictedApps: [], // This is being set at a next step !
            RegulationTypeID: authenticatedPageContextData.regulation.regulationTypeId,
        };
    }

    private _isPokerRestricted(RestrictedOfferings: number[]): boolean {
        let isRestrictedOffering = RestrictedOfferings.includes(Offering[OfferingNames.Poker]);
        if (isRestrictedOffering) return true;

        const isFeatureEnabled = this._featureAvailability.IsFeatureEnabledABTestByProperties(
            ABTestFeatures[AppIdentifiers.PokerBlast],
        );

        return isFeatureEnabled
            ? false
            : !this._featureAvailability.IsFeatureEnabled(AppIdentifiers.PokerBlast);
    }

    private _registerToLoginSuccessEvents = (): void => {
        this._loginSuccessSubscription = this._clientIntegrationFacade.loginSuccessSubscribe(
            async () => {
                this._loginSuccessSubscription?.unsubscribe();
                await this._registerPush();
                await this._clientLoginSuccessBossMode();
                this._registerSmartBanner();
            },
        );
    };

    private async _tokenSubscriber(): Promise<void> {
        const { initThirdPartyPublish } = this._clientIntegrationFacade;
        const getTokenResponse = await TokenApi.GetToken();
        if (getTokenResponse?.ErrorCode == GetTokenErrorCodes.NoRecordsFound) {
            await this._cfLogoutService.doLogoutAndShowTimeoutDialog();
        }
        const token = getTokenResponse && getTokenResponse.Token;
        initThirdPartyPublish(token);
    }
}

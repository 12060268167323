import { Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';
import { IUkMigration } from './interfaces/IUkMigration';
const moduleLock = new Lock();

export const UkMigrationToken = new Token<IUkMigration>('UkMigration');

@Service()
export class UkMigrationLoader extends BaseModuleLoader<IUkMigration> {
    constructor() {
        super('UkMigrationLoader');
    }

    protected _createModuleInstance = async (): Promise<IUkMigration> => {
        const module = await import(
            /* webpackChunkName: "uk-migration" */
            './uk-migration'
        );
        return new module.UkMigration();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IUkMigration> => UkMigrationToken;

    protected override _canUse = async (): Promise<boolean> => {
        const migrationStateId =
            this._window.pageContextManager.getMigrationData()?.configuration?.stateId;
        return migrationStateId >= 5;
    };
}

import { ApiResponse, HTTPVerb } from '../Modules/Utils/API/Interfaces/IApi';
import { ApiManager } from '../Modules/Utils/API/ApiManager';

enum APIConstants {
    Eligibility = '/api/promotion-eligibility/get-promotions',
}

export interface IGetPromotionEligibilityRequest {
    cId: string;
}

export interface IGetPromotionEligibilityResponse {
    promotionId: number;
    promotionType: PromotionType;
    timerCounterInMinutes: number;
}

enum PromotionType {
    TimeBased = 'TimeBased',
}

export default class PromotionEligibilityApi {
    public static Get = (
        data: IGetPromotionEligibilityRequest,
    ): Promise<ApiResponse<Array<IGetPromotionEligibilityResponse>>> => {
        return ApiManager.getAPIInstance().retriedRequest(
            {
                url: APIConstants.Eligibility,
                method: HTTPVerb.POST,
                data,
            },
            3,
            200,
        );
    };
}

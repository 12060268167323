import Container, { Service } from 'typedi';
import { UrlUtils } from '../utils/urlUtils';
import { WindowToken } from '../../injection-tokens';

@Service()
export class FeatureApplicationCapability {
    private _urlUtils: UrlUtils;
    private readonly _window: Window;

    constructor() {
        this._window = Container.get(WindowToken);
        this._urlUtils = Container.get(UrlUtils);
    }

    public IsAppVersionHigherThan = (minimumNativeAppVersion: number): boolean => {
        const isHybrid = this._window.pageContextManager.getDeviceData().isHybrid;
        const nativeUXVersion = this._urlUtils.getNativeUXVersion();
        if (!isHybrid) return true;
        return minimumNativeAppVersion <= nativeUXVersion;
    };
}

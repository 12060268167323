import { Container, Service } from 'typedi';
import { ILogger } from '@sparkware/uc-sdk-core';
import { LoggerProvider } from '../logger';
import { WindowToken } from '../../injection-tokens';
import { IFastNet } from './IFastNet';
import { IFastNetWrapper } from './IFastNetWrapper';

@Service()
export class IsFastNetWrapper implements IFastNetWrapper {
    private readonly _logger: ILogger;
    private readonly _window: Window;

    public constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('IsFastNetWrapper');
        this._window = Container.get(WindowToken);
    }

    public evaluateBandwidth = async (): Promise<IFastNet> => {
        const resourcesURL = this._window.pageContextManager.getSiteData().resourcesUrl;

        try {
            // Dynamically import the isfastnet module
            const { default: isFastNet } = await import('isfastnet');

            return new Promise((resolve) => {
                isFastNet(
                    (data: IFastNet) => {
                        this._logger.debug(`
                            [evaluateBandwidth]: isFast: ${data?.isFast},
                            averageLatency: ${data?.averageLatency},
                            threshold: ${data?.threshold},
                            latencyR: ${data?.latencyReadings}
                        `);
                        resolve(data);
                    },
                    {
                        timesToTest: 3, // optional, number of times to load the image default is 5
                        threshold: 300, // optional, threshold in ms after which internet speed is considered slow
                        image: `${resourcesURL}/images/bandwidth.gif`, // optional, url of the tiny image to load, keep this on a CDN
                        allowEarlyExit: true, // optional, if the first request takes greater than threshold*3 ms then the function exits with false
                        verbose: true, // optional, if set, it returns an object with all calculated latency data. Overrides allowEarlyExit option (See Example "With verbose option" for usage)
                    },
                );
            });
        } catch (error) {
            this._logger.error('Failed to load isfastnet module or evaluate bandwidth', error);
            return null;
        }
    };
}

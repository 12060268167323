import { ITracking } from './interfaces/ITracking';
import { ITrackingProvider } from './interfaces';
import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { ILogger } from '@sparkware/uc-sdk-core';
import { GTMTracking } from './gtmTracking';
import { AnalyticsElasticTracking } from './analyticsElasticTracking';

@Service()
export class Tracking implements ITracking {
    private readonly _providers: Array<ITrackingProvider>;
    private readonly _logger: ILogger;
    private readonly _gtmTrackingProvider: GTMTracking;
    private readonly _analyticsElasticTracking: AnalyticsElasticTracking;

    public get Providers() {
        return this._providers;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('Tracking');
        this._gtmTrackingProvider = Container.get(GTMTracking);
        this._analyticsElasticTracking = Container.get(AnalyticsElasticTracking);
        this._providers = [this._gtmTrackingProvider, this._analyticsElasticTracking];
        this._logger.debug('Registering new tracking provider');
    }

    track = (trackedEventData: any, correlationId?: string, subComponentName?: string) => {
        if (!trackedEventData) {
            this._logger.debug('No trackedEventData provided');
            return;
        }

        if (!this._providers || !this._providers.length) {
            this._logger.debug(`No tracking providers set`);
            return;
        }

        for (let provider of this._providers)
            provider?.track(trackedEventData, correlationId, subComponentName);
    };
}

export const ClientAuthenticationTimeout = 10 * 1000; //10s

import { IPostLoginActionOptions } from '../../post-login/models/IPostLoginActionsState';
import {
    AuthenticationActionType,
    AuthenticationEncryptionType,
    AuthenticationFlowType,
    AuthenticationType,
    MigrationProductPackageEnum,
    PlayMode,
} from '../enums';
import { IBiometricsLogin } from '../../biometrics/models';
import { IDeepLink, IInitialStateData, IPerformActionTopicPayload } from '@sparkware/uc-sdk-core';
import { AuthenticatedPageContextData } from 'page-context-manager';

export interface IEnhancedLoginResult<T extends IBaseLoginReponseModel> {
    AuthenticateData?: any;
    PageContextData: AuthenticatedPageContextData;
    InitialState?: IInitialStateData;
    Response: T;
    ErrorResponse?: any;
}

export interface IAuthenticationData {
    credentials?: IAuthenticationCredentials;
    deviceuuid?: string;
    securitydata?: unknown;
    tokendata?: unknown;
}

export interface IAuthenticationCredentials {
    username: string;
    password: string;
}

export type ILoginEventData = {
    authenticationdata:
        | IAuthenticationData
        | {
              deviceuuid: string;
              credentials: {
                  username: string;
                  password: string;
              };
          };
    username?: string;
    isremembermerequest?: boolean;
};

export interface IRegularAuthenticationService {
    login: (
        eventData: ILoginEventData,
    ) => Promise<IAuthenticationResult<IRegularLoginResponseModel>>;
}

export interface IAutologinAuthenticationService {
    autoLogin: (
        autoLoginModel: IAutoLoginModel,
    ) => Promise<{ response: IEnhancedLoginResult<IAutoLoginResponseModel>; errorResponse: any }>;
}

export interface IAuthenticationResult<T extends IBaseLoginReponseModel> {
    response: IEnhancedLoginResult<T>;
    errorResponse: any;
    inputParams?: string;
}
export interface IMeasuredAuthenticationResult<T> {
    result: T;
    duration: number;
    durationFromPageHit?: number;
}

export interface IClientAuthenticationResponseModel {
    errorCode: string;
    errorDescription: string;
    isOk: boolean;
}

export interface ILoginModel {
    AppsFlyerID?: string;
    AppID?: string;
    FirebaseAppID?: string;
    FirebaseAppInstanceID?: string;
    DeviceId?: string;
    IDFV?: string;
    BundleId?: string;
    LoginCountryISO3?: string;
}

export interface IAutoLoginModel extends ILoginModel {
    AuthenticationInfo: string;
    BrandID: number;
    ClientVersion: string;
    TargetProductPackage: number;
    TargetSubBrand: number;
}

export interface IPendingAutoLoginModel extends IAutoLoginModel {
    EncryptionType: string;
    SourceProductPackage: number;
    ClientVersion: string;
    TargetProductPackage: number;
}

export interface IRegistrationAutoLoginModel extends IAutoLoginModel {
    EncryptionType: string;
    SourceProductPackage: number;
}

export interface IBiometricsAutoLoginModel extends IAutoLoginModel {
    UserInfo: string;
    EncryptionType: AuthenticationEncryptionType;
    SourceProductPackage: number;
    TargetProductPackage: number;
    LangID: string;
    ClientVersion: string;
}

export interface ICrossAutoLoginModel extends IAutoLoginModel {
    UserInfo: string;
    SourceProductPackage: number;
    SourceSubBrand?: number;
    SessionTimer?: number;
}

export interface IMigrationAutoLoginModel extends IAutoLoginModel {
    BusinessCorrelationID: string;
    PlayerMigratedCookieExist: boolean;
    SourceProductPackage: number;
}

export interface IRegularLoginModel extends IAutoLoginModel {
    LangID: string;
    LoginType: number;
    SourceProductPackage: number;
    ScreenHeight: number;
    ScreenWidth: number;
    Benchmark: number;
    DevicePixelRatio: number;
    ErrorCode?: number;
    ReasonCode?: string;
    GASessionID?: string;
    TestData?: string;
    BusinessCorrelationID?: string;
    PlayerMigratedCookieExist?: boolean;
}

export interface IAuthenticationAction {
    actionName: AuthenticationActionType;
}

export interface IAuthenticationFlow {
    type: AuthenticationFlowType;
    start: () => Promise<IAuthenticationFlowResult>;
}

export interface IAuthenticationFlowResult {
    continue: boolean;
    description: string;
}

export interface IAuthenticationFlowRunner {
    execute: (options?: ILoginOptions) => Promise<void>;
}

export abstract class BaseAuthenticationFlow implements IAuthenticationFlow {
    private _continueWith: (actionArray: Array<IAuthenticationAction>) => void;

    start: () => Promise<IAuthenticationFlowResult>;
    public abstract type: AuthenticationFlowType;

    constructor(continueWith?: (actionArray: Array<IAuthenticationAction>) => void) {
        this._continueWith = continueWith;
    }
}

export interface IAutoLoginRequestModel {
    UserInfo: string;
}

export interface IRegularLoginRequestModel {
    LangID: string;
    LoginType: number;
    BrandID: number;
    AuthenticationInfo: string;
    TargetProductPackage: number;
    SourceProductPackage: number;
    ClientVersion: string;
    ScreenHeight: number;
    ScreenWidth: number;
    Benchmark: number;
    DevicePixelRatio: number;
    ErrorCode?: number;
    ReasonCode?: string;
    DeviceID?: string;
    AppsFlyerID?: string;
    AppID?: string;
    IDFV?: string;
    FirebaseAppID?: string;
    FirebaseAppInstanceID?: string;
    GASessionID?: string;
    TestData?: string;
    TargetSubBrand: number;
}

export interface IAutoLoginResponseModel extends IBaseLoginReponseModel {
    DeepLink: string;
    CallbackUrl: string;
    BackButtonResource: any;
    IsBossMode: boolean;
}

export interface IRegularLoginResponseModel extends IBaseLoginReponseModel {}

export interface IBaseLoginReponseModel {
    Cid: number;
    BearerToken: string;
    RefreshToken: string;
    FullResponse?: ILoginPlayerResponse;
    ErrorCode: number;
    ErrorMessage: string;
    ErrorDescription: string;
    ReasonCode: number;
    RedirectToProductPackage?: MigrationProductPackageEnum;
    GeolocationData?: IGeolocationData;
    PendingToken: string;
    MainPage: string;
    SpecificNavigation: string;
    Token: string;
    LoginToken: number;
}

export interface IGeolocationData {
    CID?: number;
    GeoComplyLicenseKey?: string;
    GeoDetectionTransactionId?: string;
    PhoneNumber?: string;
    EnableGeolocation?: boolean;
}

export interface ILoginPlayerResponse {
    Cid: number;
    RegulationType: number;
    LoginId: number;
    InitialSessionId: number;
    SessionId: number;
    TokenData: ITokenInfo;
    Tokens: Array<ITokenInfo>;
    AccessToken: string;
    RestrictionXml: string;
    ReasonCode: number;
    BankrollCurrencyIso3: string;
    NickName: string;
    MobileNumber: string;
    Email: string;
    MFAChannelType?: number;
    IsTimeLimitReached: boolean;
    IsGeoLocationInProcess: boolean;
    LocationTransactionId: string;
    GeoComplyLicense: string;
    ExistingSessionData?: ISessionData;
    IsAuthenticationToken: boolean;
    LoginMethod?: number;
    LoginType: AuthenticationType;
    SportMigrationData?: SportMigrationData;
    CountryCodeForGivenIP: string;
    ErrorCode: number;
    ErrorDescription: string;
    IsOk: boolean;
    ScvAuthenticationBrandId?: number;
}

export interface ITokenInfo {
    TokenType: number;
    TokenValue: string;
}

export interface ISessionData {
    Cid: number;
    BrandId: number;
    SessionId: number;
    InitialSessionId?: number;
    SubBrandId?: number;
    ProductPackageId: number;
    SourceProductPackageId?: number;
    ClientIp: string;
    ClientPlatformId: number;
    ClientVersion?: string;
    CountryIso3?: string;
    PlayMode?: PlayMode;
    LoginType: number;
    RegulationType?: number;
}

export interface SportMigrationData {
    MigrationStatusCode: number;
    HasOlderBets?: boolean;
    MigrationStartDate: string;
    BatchNumber?: number;
    SpectateFirstLogin?: string;
    MigrationEndDate?: string;
}

export interface IAuthenticationEvent {
    isOk: boolean;
    errorCode: number;
    errorCodeString: string;
    errorDescription: string;
    authType: AuthenticationFlowType;
    includeLoadingTime: boolean;
    durationInMSFromPageHit?: number;
}

export interface IClientAuthenticationEvent extends IAuthenticationEvent {
    durationWrapperAndClientinMS: number;
}

export interface IGradualLaunchModel {
    Token: string;
    CID: number;
    HasKambiBets?: boolean;
    BatchNumber?: number;
}

export interface ILoginOptions {
    flowCallback?: IFlowCallbackOptions;
    biometrics?: IBiometricsOptions;
    postLogin?: IPostLoginActionOptions;
    action?: IPerformActionTopicPayload;
    className?: string;
}

export interface IConfirmLocationOptions {
    deeplink: IDeepLink;
}

export interface IBiometricsOptions {
    skipBiometricsEnrollment?: boolean;
}
export interface IFlowCallback {
    type: AuthenticationFlowType;
    continue: boolean;
    description: string;
}

export interface IFlowCallbackOptions {
    onFlowExecuted: (data: IFlowCallback) => void;
}

export interface IAuthenticationFlowFactory {
    create(flowType: AuthenticationFlowType): Promise<IAuthenticationFlowRunner>;
}

export interface IExecuteWithAnalyticsResult<T extends IBaseLoginReponseModel> {
    correlationID: string;
    duration: number;
    result: IAuthenticationResult<T>;
    includeLoadingTime: boolean;
    authType: AuthenticationFlowType;
}

export interface IAuthenticationService {
    login: (
        loginModel: IRegularLoginRequestModel,
    ) => Promise<IAuthenticationResult<IRegularLoginResponseModel>>;
    autoLogin: (
        autoLoginModel: IAutoLoginModel,
    ) => Promise<IAuthenticationResult<IAutoLoginResponseModel>>;
    migrationAutoLogin: (
        autoLoginModel: IMigrationAutoLoginModel,
    ) => Promise<IAuthenticationResult<IAutoLoginResponseModel>>;
    continuePostLoginActions: (
        response: IEnhancedLoginResult<IBaseLoginReponseModel>,
    ) => Promise<void>;
    executeWithAnalytics: <T extends IBaseLoginReponseModel>(
        authType: AuthenticationFlowType,
        method: () => Promise<IAuthenticationResult<T>>,
        includeLoadingTime?: boolean,
    ) => Promise<IExecuteWithAnalyticsResult<T>>;
    startOfferingAuthentication: <T extends IBaseLoginReponseModel>(
        loginResult: IExecuteWithAnalyticsResult<T>,
    ) => Promise<void>;
}

export interface IBiometricsAuthenticationService {
    autoLogin: (
        biometricsLoginData: IBiometricsLogin,
    ) => Promise<IAuthenticationResult<IAutoLoginResponseModel>>;
}

export interface IAuthenticationStorage {
    getLoginOptions: () => ILoginOptions;
    setLoginOptions: (options: ILoginOptions) => void;
    updateLoginOptions: (options: ILoginOptions) => void;
}

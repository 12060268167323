import Container, { Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { Metric, onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals/attribution';

import { ITracking } from './models/interfaces/ITracking';
import { WebVitalsTracking } from './models/web-vitals-tracking';
import { ILogger } from '@sparkware/uc-sdk-core';
import { Utils } from '../utils';

@Service()
export class WebVitals {
    private readonly _logger: ILogger;
    private readonly _utils: Utils;
    private readonly _webVitalsTracking: ITracking;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('WebVitals');
        this._webVitalsTracking = Container.get(WebVitalsTracking);
        this._utils = Container.get(Utils);
    }

    public registerHandlers(): void {
        onCLS(this._sendReport);
        onFCP(this._sendReport);
        onFID(this._sendReport);
        onTTFB(this._sendReport);
        onLCP(this._sendReport);
        onINP(this._sendReport);
    }

    private _sendReport = (metric: Metric) => {
        this._logger.debug(`_sendReport: ${this._utils.safeStringify(metric, 2)}`);
        this._webVitalsTracking.track(metric);
    };
}

import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { IClientsFrameworkRegistrationService } from './interfaces';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WindowToken } from '../../../injection-tokens';

@Service()
export class ClientsFrameworkRegistrationService implements IClientsFrameworkRegistrationService {
    private readonly _logger: ILogger;
    private readonly _window: Window;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger(
            'ClientsFrameworkRegistrationService',
        );
        this._window = Container.get(WindowToken);
    }

    public Open = async (
        width,
        height,
        dlParam?: any,
        lang?: string,
        onCloseCallback?: Function,
    ) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Registration.Open(
                width,
                height,
                dlParam,
                lang,
                onCloseCallback,
            ),
        );
    };

    public FYP = async (width, height) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Registration.FYP(width, height),
        );
    };

    public FYU = async (width, height) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Registration.FYU(width, height),
        );
    };
}

import Container from 'typedi';
import {
    DataStoreRefreshToken,
    LocalStorageToken,
    PerformanceToken,
    SessionStorageToken,
    SessionTimerInterval,
    WindowToken,
} from '../../../../../injection-tokens';
import { Config } from '../../../../../models/enums/general-enums';
import { ExtendedWindow } from '../../../../../../types/globals';
import { BrandUtils } from '../../../../../../Modules/Utils/BrandUtils';
import { ApiManager } from '../../../../../../Modules/Utils/API/ApiManager';

export const setMutualServices = () => {
    try {
        Container.set(SessionStorageToken, sessionStorage);
        Container.set(LocalStorageToken, localStorage);
        Container.set(PerformanceToken, performance);
        Container.set(SessionTimerInterval, Config.SessionTimerInterval);
        Container.set(DataStoreRefreshToken, window.dataStoreRefreshDO);
    } catch (err) {
        console.error('setMutualServices failed');
    }
};

export const initWindowAndStaticClass = () => {
    Container.set(WindowToken, window as ExtendedWindow);
    BrandUtils.initialize(window);
    ApiManager.initApi(window);
};

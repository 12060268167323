import Container from 'typedi';
import {
    ClientIntegrationFacadeToken,
    FeatureAvailabilityToken,
    UCFData,
} from '../../../injection-tokens';
import { ClientSettings } from './client-settings';
import { SpectateClientIntegrationFacade } from '../spectate-client-integration.facade';
import { ISpectateClientSettings } from '@sparkware/uc-sdk-core';
import { DeviceUtils } from '../../utils/deviceUtils';
import { Features } from '../../../models/enums/general-enums';
import { IFeatureAvailability } from '../../feature/feature-availability/feature-availability-interface';

export class SpectateClientSettings extends ClientSettings {
    private readonly _featureAvailability: IFeatureAvailability;
    private readonly _deviceUtils: DeviceUtils;
    private readonly _ucfData: any;

    constructor() {
        super();
        this._deviceUtils = Container.get(DeviceUtils);
        this._featureAvailability = Container.get(FeatureAvailabilityToken);
        this._ucfData = Container.get(UCFData);
    }

    public getClientSettings = (): ISpectateClientSettings => {
        const clientSettingsBase = this.getBaseClientSettings();
        const hasKambiBets = this._window.pageContextManager.getUserData().hasKambiBets;
        const marketingBrandId = this._window.pageContextManager.getBrandData().marketingBrandId;
        const allowLogin = this.shouldAllowLogin();
        return {
            ...clientSettingsBase,
            HasKambiBets: hasKambiBets,
            pageCorrelationId: this._ucfData?.pageCorrelationId,
            marketingBrandID: marketingBrandId,
            nativeAppType: this._deviceUtils.getNativeAppType(),
            allowLogin,
        };
    };

    protected registerTokens() {
        super.registerTokens();
        Container.set(ClientIntegrationFacadeToken, new SpectateClientIntegrationFacade());
    }

    /**
     * determine if spectate permit log-in from bet slip.
     * False - show prompt "Download app"
     * True - show LoginButton
     */
    private shouldAllowLogin = () => {
        const isGeoComplyNDBSEnabled = this._featureAvailability.IsFeatureEnabled(
            Features.ENABLE_GC_NDBS,
        );
        const { isMobile } = this._window.pageContextManager.getDeviceData();
        const { geolocationEnabled } = this._window.pageContextManager.getRegulationData();
        return !(isMobile && geolocationEnabled && !isGeoComplyNDBSEnabled);
    };
}

import {
    AuthenticationFlowType,
    AuthenticationPerformanceMarks,
    AuthenticationPerformanceMeasures,
} from '../enums';
import {
    ClientAuthenticationTimeout,
    IAuthenticationResult,
    IAutoLoginModel,
    IAutoLoginResponseModel,
    IBaseLoginReponseModel,
    IClientAuthenticationResponseModel,
    IEnhancedLoginResult,
    IMeasuredAuthenticationResult,
    IMigrationAutoLoginModel,
    IRegularLoginRequestModel,
    IRegularLoginResponseModel,
    IAuthenticationService,
    IAuthenticationStorage,
    IExecuteWithAnalyticsResult,
    ILoginOptions,
} from '../models';
import Container, { Service } from 'typedi';

import AuthenticationApi from '../../../../APIs/AuthenticationApi';
import { AuthenticationUtils } from '../utils/authentication.utils';
import { BiometricsUtils } from '../../biometrics/biometrics.utils';
import { ClientIntegrationFacadeToken, WindowToken } from '../../../injection-tokens';
import ContextApi from '../../../../APIs/ContextApi';
import {
    GENERIC_LOGIN_ERROR_CODE,
    PlatformLogoutReason,
} from '../../../models/enums/general-enums';
import {
    ILoginSuccessData,
    MessageBroker,
    IdentityChannel,
    ISportMigrationData,
    ILogger,
} from '@sparkware/uc-sdk-core';
import { StorageItemEnum } from '../../../models/enums/storage-enums';
import { Utils } from '../../utils';
import { WebLoginService } from '../../web-login/web.login.service';
import {
    ClientsFrameworkInitService,
    ClientsFrameworkLogoutService,
} from '../../external/clients-framework';
import { LoggerProvider } from '../../logger';
import { UserareaService } from '../../external/userarea';
import StringUtils from '../../../../Modules/Utils/StringUtils';
import { LocalSimpleStoreService } from '../../storage/implementations/simple-store';
import { UrlUtils } from '../../utils/urlUtils';
import { IClientIntegrationFacade } from '../../client-integration/interfaces/IClientIntegrationFacade';
import { IMrGreenMigration } from '../../mr-green-migration/models/interfaces/IMrGreenMigration';
import { IBiometricsService } from '../../biometrics/models/IBiometricsService';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { IUkMigration } from '../../uk-migration/interfaces/IUkMigration';
import { ILoginSuccessUCData } from '../../global-subscriber/mb-channels';
import { UcCookie } from '../../uc-cookie/uc-cookie';
import { IUcCookie } from '../../uc-cookie/interfaces/IUcCookie';
import DeferredObject from '../../../../Modules/Utils/DeferredObject';

@Service()
export class AuthenticationService implements IAuthenticationService {
    private _loginTimeout: string | number | NodeJS.Timeout;
    protected readonly _window: Window;
    protected readonly _logger: ILogger;
    private readonly _promiseClientLogin: DeferredObject<void>;
    private readonly _authenticationUtils: AuthenticationUtils;
    private readonly _biometricsUtils: BiometricsUtils;
    private readonly _cfInitService: ClientsFrameworkInitService;
    private readonly _cfLogoutService: ClientsFrameworkLogoutService;
    private readonly _clientIntegrationFacade: IClientIntegrationFacade;
    private readonly _identityChannel: IdentityChannel;
    private readonly _utils: Utils;
    private readonly _webLoginService: WebLoginService;
    private readonly _userAreaService: UserareaService;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;
    private readonly _urlUtils: UrlUtils;
    private readonly _ucCookie: IUcCookie;

    private get _authenticationStoragePromise(): Promise<IAuthenticationStorage> {
        return LoaderManager.Instance.AuthenticationStorageLoader.Instance;
    }

    private get _biometricsServicePromise(): Promise<IBiometricsService> {
        return LoaderManager.Instance.BiometricsServiceLoader.Instance;
    }

    private get _mrGreenMigrationPromise(): Promise<IMrGreenMigration> {
        return LoaderManager.Instance.MrGreenMigrationLoader.Instance;
    }

    private get _ukMigrationPromise(): Promise<IUkMigration> {
        return LoaderManager.Instance.UkMigrationLoader.Instance;
    }

    constructor() {
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('AuthenticationService');
        this._identityChannel = MessageBroker.getInstance().identity;
        this._authenticationUtils = Container.get(AuthenticationUtils);
        this._clientIntegrationFacade = Container.get(ClientIntegrationFacadeToken);
        this._biometricsUtils = Container.get(BiometricsUtils);
        this._cfInitService = Container.get(ClientsFrameworkInitService);
        this._cfLogoutService = Container.get(ClientsFrameworkLogoutService);
        this._webLoginService = Container.get(WebLoginService);
        this._utils = Container.get(Utils);
        this._urlUtils = Container.get(UrlUtils);
        this._userAreaService = Container.get(UserareaService);
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
        this._ucCookie = Container.get(UcCookie);
        this._promiseClientLogin = new DeferredObject<void>();
    }

    public login = async (
        loginModel: IRegularLoginRequestModel,
    ): Promise<IAuthenticationResult<IRegularLoginResponseModel>> => {
        const { response, errorResponse } = await AuthenticationApi.Login(loginModel);

        const enhancedResponse: IEnhancedLoginResult<IBaseLoginReponseModel> =
            await this._enhanceLoginResponse(response, errorResponse);

        if (enhancedResponse.ErrorResponse) {
            this._logger.error(
                '_enhanceLoginResponse: Error while retrieving authenticated context',
                errorResponse,
            );
            this._cfLogoutService.doLogout(PlatformLogoutReason.TechnicalProblem);
            await this._processWebLoginResponse();
        }

        const returnedResponse: IAuthenticationResult<IRegularLoginResponseModel> = {
            errorResponse: errorResponse || enhancedResponse.ErrorResponse,
            response: enhancedResponse as IEnhancedLoginResult<IRegularLoginResponseModel>,
            inputParams: JSON.stringify(loginModel),
        };

        return returnedResponse;
    };

    public autoLogin = async (
        autoLoginModel: IAutoLoginModel,
    ): Promise<IAuthenticationResult<IAutoLoginResponseModel>> => {
        const { response, errorResponse } = await AuthenticationApi.AutoLogin(autoLoginModel);

        const enhancedResponse: IEnhancedLoginResult<IBaseLoginReponseModel> =
            await this._enhanceLoginResponse(response, errorResponse);

        const returnedResponse: IAuthenticationResult<IAutoLoginResponseModel> = {
            errorResponse,
            response: enhancedResponse as IEnhancedLoginResult<IAutoLoginResponseModel>,
            inputParams: JSON.stringify(autoLoginModel),
        };

        return returnedResponse;
    };

    public migrationAutoLogin = async (
        autoLoginModel: IMigrationAutoLoginModel,
    ): Promise<IAuthenticationResult<IAutoLoginResponseModel>> => {
        const { response, errorResponse } =
            await AuthenticationApi.MigrationAutoLogin(autoLoginModel);

        const enhancedResponse: IEnhancedLoginResult<IBaseLoginReponseModel> =
            await this._enhanceLoginResponse(response, errorResponse);

        const returnedResponse: IAuthenticationResult<IAutoLoginResponseModel> = {
            errorResponse,
            response: enhancedResponse as IEnhancedLoginResult<IAutoLoginResponseModel>,
            inputParams: JSON.stringify(autoLoginModel),
        };

        return returnedResponse;
    };

    public startOfferingAuthentication = async (
        loginResult: IExecuteWithAnalyticsResult<IBaseLoginReponseModel>,
    ): Promise<void> => {
        try {
            const { Response } = loginResult.result.response;
            if (!Response) {
                this._logger.error(
                    '[startOfferingAuthentication] missing Response from login results',
                );
                return;
            }
            const { ErrorCode } = Response;
            if (ErrorCode !== 0) return;
            const ukMigration = await this._ukMigrationPromise;
            if (
                ukMigration &&
                ukMigration.shouldWaitForOfferingAuthentication(Response?.FullResponse)
            ) {
                await this.offeringAuthenticationSucceeded(loginResult);
            } else {
                void this.offeringAuthenticationSucceeded(loginResult);
            }
        } catch (err) {
            this._logger.error('[startOfferingAuthentication] failed', err);
        }
    };

    public executeWithAnalytics = async <T extends IBaseLoginReponseModel>(
        authType: AuthenticationFlowType,
        method: () => Promise<IAuthenticationResult<T>>,
        includeLoadingTime: boolean = false,
    ): Promise<IExecuteWithAnalyticsResult<T>> => {
        const correlationID = this._utils.generateCorrelationID();
        const authenticationPerformanceRes: IMeasuredAuthenticationResult<any> =
            await this._authenticationUtils.measureAuthenticationPerformance(
                method,
                AuthenticationPerformanceMarks.AuthenticationStart,
                AuthenticationPerformanceMeasures.AuthenticationTime,
                AuthenticationPerformanceMeasures.AuthenticationTimeFromPageHit,
                includeLoadingTime,
            );
        const { result, duration, durationFromPageHit } = authenticationPerformanceRes;
        this._authenticationUtils.sendAuthenticationEvent(
            authType,
            result,
            duration,
            durationFromPageHit,
            correlationID,
        );
        return {
            correlationID,
            duration,
            result,
            includeLoadingTime,
            authType,
        };
    };

    public continuePostLoginActions = async (
        response: IEnhancedLoginResult<IBaseLoginReponseModel>,
    ) =>
        new Promise<void>(async (resolve) => {
            const authenticationStorage = await this._authenticationStoragePromise;
            const loginOptions = authenticationStorage.getLoginOptions();
            const biometricsEnabled = await this._biometricsUtils.FindIfBiometricsFlowIsEnabled();
            const touchIdTimeout = this._window.pageContextManager.getTouchIdData().timeout || 0;
            const completeAction = async () => {
                await this._cfInitService.RealityCheck();
                this._authenticationUtils.resetABFeatures();
                await this._loginSuccessPublish(
                    {
                        InitialState: response.InitialState,
                        AuthenticateData: response.AuthenticateData,
                        IsClientAuthenticationRequired: true,
                        ScvAuthenticationBrandId:
                            response.Response?.FullResponse?.ScvAuthenticationBrandId,
                        SportMigrationData: response.Response?.FullResponse
                            ?.SportMigrationData as ISportMigrationData,
                    },
                    loginOptions,
                );
                resolve();
            };
            if (biometricsEnabled && !loginOptions?.biometrics?.skipBiometricsEnrollment) {
                await new Promise<void>((resolve) => {
                    setTimeout(async () => {
                        const biometricsService = await this._biometricsServicePromise;
                        biometricsService.enrollment({
                            cid: String(response.Response.Cid),
                            finishCallback: async () => {
                                await completeAction();
                                resolve();
                            },
                        });
                    }, touchIdTimeout);
                });
            } else {
                await completeAction();
            }
        });

    public onClientIntegrationLoginResponse = (errorData?: any) => {
        clearTimeout(this._loginTimeout);
        if (errorData) {
            this._promiseClientLogin.reject(errorData?.error || errorData);
        } else {
            this._promiseClientLogin.resolve();
        }
    };

    private offeringAuthenticationSucceeded = async (
        loginResult: IExecuteWithAnalyticsResult<IBaseLoginReponseModel>,
    ): Promise<void> => {
        const { result, authType, includeLoadingTime, duration, correlationID } = loginResult;
        const isWrapperLoggedIn = !!result.response.Response?.BearerToken;
        const response: IMeasuredAuthenticationResult<IClientAuthenticationResponseModel> =
            await this._authenticationUtils.measureAuthenticationPerformance(
                () => this._handleClientLogin(isWrapperLoggedIn),
                AuthenticationPerformanceMarks.ClientAuthenticationStart,
                AuthenticationPerformanceMeasures.ClientAuthenticationTime,
                AuthenticationPerformanceMeasures.ClientAuthenticationTimeFromPageHit,
                includeLoadingTime,
            );
        const clientDuration = response.duration;
        const clientDurationFromPageHit = response.durationFromPageHit;
        const wraperAndClientDuration = duration + response.duration;
        this._authenticationUtils.sendClientAuthenticationEvent(
            authType,
            response.result,
            clientDuration,
            wraperAndClientDuration,
            clientDurationFromPageHit,
            correlationID,
        );
    };

    /**
     * Waiting for Wrapper and Client to be ready to receive this message broker message. This is mostly important to Cross Autologin
     * @param data Authentication dataimport { Geolocation } from './../../geolocation/geolocation';
     * @param loginOptions
     * @returns Promise
     */
    private _loginSuccessPublish = async (data: ILoginSuccessData, loginOptions: ILoginOptions) => {
        const isClientMode = this._urlUtils.isClientMode();
        const { ReadyToConnect } = this._clientIntegrationFacade;

        const wrapperReady = isClientMode
            ? await Promise.resolve()
            : await this._userAreaService.executeOnload(() => {});

        const [_, ready] = await Promise.all([wrapperReady, ReadyToConnect.promise]);
        if (ready) {
            const loginSuccessUCData: ILoginSuccessUCData = { ...data, loginOptions };
            this._identityChannel.topics.loginSuccess.publish(
                { publisher: 'UC:AuthenticationService' },
                loginSuccessUCData,
            );
        } else {
            this._logger.error(
                'loginPublish: Client not able to receive MB events. Doing logout...',
            );
            await this._cfLogoutService.doLogout(PlatformLogoutReason.TechnicalProblem);
        }
    };

    private _enhanceLoginResponse = async (
        result: IBaseLoginReponseModel,
        errorResponse: any,
    ): Promise<IEnhancedLoginResult<IBaseLoginReponseModel>> => {
        this._authenticationUtils.updateStorageIndications();
        this._authenticationUtils.setBossMode(result);

        let enhancedResult: IEnhancedLoginResult<IBaseLoginReponseModel> = {
            Response: result,
            AuthenticateData: null,
            PageContextData: null,
            InitialState: null,
            ErrorResponse: null,
        };

        this._authenticationUtils.closeIframeContainers();

        let shouldKeepWebLoginShown = false;
        const ukMigration = await this._ukMigrationPromise;
        if (result?.BearerToken) {
            if (ukMigration) {
                shouldKeepWebLoginShown = ukMigration.shouldWaitForOfferingAuthentication(
                    result.FullResponse,
                );
            }
            this._authenticationUtils.updateAuthorizationData(result);

            try {
                const authenticatedPageContextPromise = ContextApi.GetAuthenticatedContextData();
                const storeStatePromise = ContextApi.GetStoreState();

                const authenticatedPageContextResponse = await authenticatedPageContextPromise;
                const storeStateResponse = await storeStatePromise;

                if (
                    authenticatedPageContextResponse.errorResponse ||
                    storeStateResponse.errorResponse
                ) {
                    enhancedResult.Response = null;
                    enhancedResult.ErrorResponse = [
                        authenticatedPageContextResponse.errorResponse,
                        storeStateResponse.errorResponse,
                    ]
                        .filter((error) => !!error)
                        .join(' | ');

                    this._authenticationUtils.removeAuthorizationData();
                    return enhancedResult;
                }

                this._authenticationUtils.setAuthenticatedPageContextData(
                    authenticatedPageContextResponse.response,
                );
                await this._authenticationUtils.updateLegacyStorageIndications(result);

                this._authenticationUtils.sendPlayerStatus();
                this._authenticationUtils.setGeolocationData(result);
                const authenticateData =
                    await this._authenticationUtils.processGradualLaunch(result);
                this._authenticationUtils.removeRealityCheckTimeout();

                enhancedResult.AuthenticateData = authenticateData;
                if (!!enhancedResult.AuthenticateData) {
                    enhancedResult.AuthenticateData.RegulationTypeID =
                        this._window.pageContextManager.getRegulationData().regulationTypeId;
                }

                enhancedResult.PageContextData = authenticatedPageContextResponse.response;
                enhancedResult.InitialState = storeStateResponse.response;

                this._localSimpleStoreService.remove(StorageItemEnum.UserOnBackToForeground);

                if (!shouldKeepWebLoginShown) {
                    this._webLoginService.ResetLoginForm();
                }

                const mrGreenMigration = await this._mrGreenMigrationPromise;
                if (mrGreenMigration) {
                    mrGreenMigration.OnLoginSuccess();
                }
            } catch (e) {
                enhancedResult.Response = null;
                enhancedResult.ErrorResponse = errorResponse;
                this._authenticationUtils.removeAuthorizationData();

                return enhancedResult;
            }
        } else {
            await this._onFailLogin();
        }

        if (!shouldKeepWebLoginShown) {
            await this._processWebLoginResponse(result);
        }

        return enhancedResult;
    };

    private async _onFailLogin() {
        this._ucCookie.removePropertyFromUcCookie('forceBrandId');
        const mrGreenMigration = await this._mrGreenMigrationPromise;
        mrGreenMigration?.OnLoginFailed();
    }

    private _processWebLoginResponse = async (response?: IBaseLoginReponseModel) => {
        const responseToProcess = response || { ErrorCode: GENERIC_LOGIN_ERROR_CODE };
        await this._webLoginService.HandleWebLoginResponse(
            responseToProcess as IBaseLoginReponseModel,
        );
    };

    private _handleClientLogin = async (isWrapperLoggedIn: boolean) => {
        const response: IClientAuthenticationResponseModel = {
            isOk: true,
            errorCode: undefined,
            errorDescription: undefined,
        };
        try {
            this._resolveClientLogin(isWrapperLoggedIn);
            await this._promiseClientLogin;
        } catch (err) {
            response.isOk = false;
            response.errorCode = err.errorCode;
            response.errorDescription = StringUtils.toString(err.errorDescription);
        }

        return response;
    };

    private _resolveClientLogin = (isWrapperAuthenticated: boolean) => {
        if (!isWrapperAuthenticated)
            this._promiseClientLogin.reject({
                errorCode: 1,
                errorDescription:
                    'Client authentication aborted because wrapper authentication failed',
            });

        this._loginTimeout = setTimeout(() => {
            this._promiseClientLogin.reject({
                errorCode: 1,
                errorDescription: `${
                    ClientAuthenticationTimeout / 1000
                } seconds passed since client-authentication started`,
            });
        }, ClientAuthenticationTimeout);
    };
}

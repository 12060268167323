import { IUcCookie } from './interfaces/IUcCookie';
import { IUcCookieOptions } from './interfaces/IUcCookieOptions';
import Container, { Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { CookieStoreItemService } from '../storage/implementations';
import { WindowToken } from '../../injection-tokens';
import { ClientTracking } from '../tracking/models/clientTracking';
import { UkMigrationElasticEvents } from '../uk-migration/enums';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class UcCookie implements IUcCookie {
    private ucCookieName = '_uc';
    private readonly _logger: ILogger;
    private readonly _cookieStoreItemService: CookieStoreItemService;
    private readonly _window: Window;
    private readonly _clientTracking: ClientTracking;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('UcCookie');
        this._cookieStoreItemService = Container.get(CookieStoreItemService);
        this._window = Container.get(WindowToken);
        this._clientTracking = Container.get(ClientTracking);
    }

    public getUcCookieOptions(): IUcCookieOptions {
        const hostWithoutSubdomains = this._window.location.host.replace(
            /^(https?:\/\/)?([^./]+\.)*([^.]+\.[^.]+)(\/.*)?$/,
            '.$3',
        );
        return {
            expires: 7,
            secure: true,
            path: '/',
            domain: hostWithoutSubdomains,
        };
    }

    /**
     * object of the full new _uc cookie
     * @param cookieJsonObj
     */
    public updateUcCookie(cookieJsonObj: Record<string, any>): void {
        try {
            const ucCookieOptions = this.getUcCookieOptions();
            const envelop = { headers: { publisher: 'UcCookie' }, topic: null };
            const trackingEvent = {
                dataString: JSON.stringify({ ...cookieJsonObj, ucCookieOptions }),
                event: UkMigrationElasticEvents.UpdateUcCookie,
            };
            this._clientTracking.onSendEvent(trackingEvent, envelop);
            const cookieVal = encodeURIComponent(JSON.stringify(cookieJsonObj));
            this._cookieStoreItemService.set(this.ucCookieName, cookieVal, ucCookieOptions);
        } catch (error) {
            this._logger.error('[updateUcCookie] Failed', error);
        }
    }

    public removePropertyFromUcCookie(propKey: string): void {
        try {
            const ucCookieStr = this._cookieStoreItemService.get(this.ucCookieName);
            if (!ucCookieStr) return;

            const cookieDecodedValue = decodeURIComponent(ucCookieStr);
            const ucCookie = JSON.parse(cookieDecodedValue);
            if (!ucCookie.hasOwnProperty(propKey)) return;

            delete ucCookie[propKey];
            const cookieVal = encodeURIComponent(JSON.stringify(ucCookie));
            const ucCookieOptions = this.getUcCookieOptions();
            this._cookieStoreItemService.set(this.ucCookieName, cookieVal, ucCookieOptions);

            const envelop = { headers: { publisher: 'UcCookie' }, topic: null };
            const trackingEvent = {
                dataString: JSON.stringify({ propKey, ucCookie, ucCookieOptions }),
                event: UkMigrationElasticEvents.RemovePropertyFromUcCookie,
            };
            this._clientTracking.onSendEvent(trackingEvent, envelop);
        } catch (error) {
            this._logger.error('[removePropertyFromUcCookie] Failed', error);
        }
    }
}

import { AxiosRequestConfig } from 'axios';
import { AuthenticatedPageContextData } from 'page-context-manager';
import { IInitialStateData } from '@sparkware/uc-sdk-core';
import { ApiResponse, HTTPVerb } from '../Modules/Utils/API/Interfaces/IApi';
import { ApiManager } from '../Modules/Utils/API/ApiManager';

enum APIConstants {
    GetContextDataStore = '/api/context/store/',
    GetAuthenticatedContextData = '/api/context/authenticated/',
    GetStoreState = '/api/context/store-state/',
}

export default class ContextApi {
    public static GetContextDataStore = async (): Promise<ApiResponse<any>> => {
        const requestOptions: AxiosRequestConfig = {
            url: APIConstants.GetContextDataStore,
            method: HTTPVerb.GET,
        };

        return ApiManager.getAPIInstance().request(requestOptions);
    };

    public static GetAuthenticatedContextData = async (): Promise<
        ApiResponse<AuthenticatedPageContextData>
    > => {
        const requestOptions: AxiosRequestConfig = {
            url: APIConstants.GetAuthenticatedContextData,
            method: HTTPVerb.GET,
        };

        return ApiManager.getAPIInstance().authenticatedRequest(requestOptions);
    };

    public static GetStoreState = async (): Promise<ApiResponse<IInitialStateData>> => {
        const requestOptions: AxiosRequestConfig = {
            url: APIConstants.GetStoreState,
            method: HTTPVerb.GET,
        };

        return ApiManager.getAPIInstance().authenticatedRequest(requestOptions);
    };
}

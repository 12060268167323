import { ClientInit } from './client-init';
import { ILoginSuccessData } from '@sparkware/uc-sdk-core';
import { Service } from 'typedi';

@Service()
export class KambiClientInit extends ClientInit {
    constructor() {
        super();
    }

    protected override async onLoginSuccess(data: ILoginSuccessData) {
        await super.onLoginSuccess(data);
    }
}

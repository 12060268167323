import {
    IContentAdapter,
    IContentAdapterCasinoGameInfo,
    IContentAdapterCasinoGamesInfoResponse,
    IContentAdapterClientInfo,
    IContentAdapterElasticEventData,
    IContentAdapterEvents,
    IContentAdapterUserInfo,
    IDeepLink,
} from '@sparkware/uc-sdk-core';
import Container, { Service } from 'typedi';
import { FlowsRunner } from '../flows-runner';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import {
    DataStoreRefreshToken,
    FeatureAvailabilityToken,
    UserContextToken,
    UserDataStoreDeferredObjectToken,
    WindowToken,
} from '../../injection-tokens';
import { IUserContext } from '../user-context/user-context-interface';
import { ClientProvider, TestName } from '../../models/enums/general-enums';
import { ISessionUserData } from '../session-manager/interfaces/ISessionUserData';

@Service()
export class ContentAdapter implements IContentAdapter {
    events: IContentAdapterEvents;
    private readonly _window: Window;
    private readonly _flowsRunner: FlowsRunner;
    private readonly _userContext: IUserContext;
    private readonly _userDataStoreDeferredObject: DeferredObject<ISessionUserData>;
    private readonly _dataStoreRefreshToken: DeferredObject<void>;

    constructor() {
        this._window = Container.get(WindowToken);
        this._flowsRunner = Container.get(FlowsRunner);
        this._userContext = Container.get(UserContextToken);
        this._userDataStoreDeferredObject = Container.get(UserDataStoreDeferredObjectToken);
        this._dataStoreRefreshToken = Container.get(DataStoreRefreshToken);
    }

    async getUserInfo(): Promise<IContentAdapterUserInfo> {
        await this._userDataStoreDeferredObject.promise;
        const { isPromotionPermitted } = this._window.pageContextManager.getUserData();
        const featureAvailability = Container.get(FeatureAvailabilityToken);
        const abTests = await featureAvailability.GetActiveAbTestFeatures();

        const userInfo: IContentAdapterUserInfo = {
            isAuthorized: this._userContext.IsAuthenticated,
            abTestGroup: abTests.find((test) => test.TestName === TestName.B2CABTest)?.TestGroup,
            userData: {
                isPromotionPermitted: isPromotionPermitted,
            },
        };

        return new Promise<IContentAdapterUserInfo>((resolve, reject) => {
            return resolve(userInfo);
        });
    }

    async getClientInfo(): Promise<IContentAdapterClientInfo> {
        await this._dataStoreRefreshToken.promise;
        const { brandId, subBrandId } = this._window.pageContextManager.getBrandData();
        const { productPackage, isMobile, isHybrid, isIOS } =
            this._window.pageContextManager.getDeviceData();
        const { langId } = this._window.pageContextManager.getLocalizationData();
        const { systemId, environment } = this._window.pageContextManager.getEnvironmentData();
        const { name } = this._window.pageContextManager.getClientProviderData();
        const { componentId, spectateComponentId } = this._window.pageContextManager.getInfraData();
        const { clientPage } = this._window.pageContextManager.getSurveyData();

        const clientInfo: IContentAdapterClientInfo = {
            brandId: brandId,
            subBrandId: subBrandId,
            productPackageId: productPackage,
            systemId: parseInt(systemId),
            languageIso2: langId,
            device: {
                isMobile,
                isHybrid,
                isIOS,
            },
            environment,
            cms: {
                footerComponentId:
                    name === ClientProvider.Spectate && !!spectateComponentId
                        ? spectateComponentId
                        : componentId,
            },
            features: {
                survey: {
                    pageURI: clientPage,
                },
            },
        };

        return new Promise<IContentAdapterClientInfo>((resolve, reject) => {
            return resolve(clientInfo);
        });
    }

    openDeepLink(deepLink: IDeepLink): Promise<void> {
        return this._flowsRunner.runDeeplink(deepLink);
    }

    sendElasticInfoEvent(data: IContentAdapterElasticEventData): boolean {
        return true;
    }

    getCasinoGames(gameTypes: number[]): Promise<IContentAdapterCasinoGamesInfoResponse> {
        throw new Error('Method not implemented.');
    }

    getCasinoGamesWithoutBonus(): Promise<Record<number, IContentAdapterCasinoGameInfo>> {
        throw new Error('Method not implemented.');
    }

    showPwaPrompt(): Promise<void> {
        throw new Error('Method not implemented.');
    }

    isPwaInstallable(): Promise<boolean> {
        throw new Error('Method not implemented.');
    }
}

import ApiConstants from '../Modules/Utils/API/Constants/ApiConstants';
import { HTTPVerb } from '../Modules/Utils/API/Interfaces/IApi';
import { ApiManager } from '../Modules/Utils/API/ApiManager';

export let PageContentTokens = [];
export let PageTitleTokens = [];

enum APIConstants {
    GetFooter = '/api/content',
    GetPageContent = '/api/pagecontent',
    GetUrlData = '/api/urldata',
}

export default class ContentApi {
    public static GetFooter = async (publicationId, templateId, componentId, context) => {
        const searchParams = {
            templateId: templateId,
            publicationId: publicationId,
            pagePlaceHoldersXML: null,
            componentId: [componentId],
        };

        const url = _getRelativeURLWithSearchParams(APIConstants.GetFooter, searchParams);

        const requestOptions: any = {
            headers: ContentApi.GetContextHeaders(context),
            url,
            method: HTTPVerb.GET,
            enableCaching: true,
            timeout: ApiConstants.ContentApiTimeout,
        };

        const { response } = await ApiManager.getNonServiceInstance().request(requestOptions);

        const content =
            typeof response !== 'undefined' && Array.isArray(response) && response.length > 0
                ? response[0].Content
                : '';

        return content;
    };

    public static GetPageContent = async (uri, publicationId, context) => {
        const headers = ContentApi.GetContextHeaders(context);

        PageContentTokens.forEach((cancel) => cancel());

        const cancelToken = ApiManager.getNonServiceInstance().createCancelToken((c) => {
            PageContentTokens.push(c);
        });

        const searchParams = {
            publicationId,
            url: uri,
        };

        const url = _getRelativeURLWithSearchParams(APIConstants.GetPageContent, searchParams);

        const requestOptions = {
            url,
            method: HTTPVerb.GET,
            headers: headers,
            cancelToken,
            enableCaching: true,
            timeout: ApiConstants.ContentApiTimeout,
        };
        return await ApiManager.getNonServiceInstance().request(requestOptions);
    };

    public static GetTitle = async (uri, brandId, subBrandId, regulationId, language) => {
        const searchParams = {
            brandId: brandId,
            subBrandId: subBrandId,
            regulationId: regulationId,
            language: language,
            url: uri,
        };

        PageTitleTokens.forEach((cancel) => cancel());

        const cancelToken = ApiManager.getNonServiceInstance().createCancelToken((c) => {
            PageTitleTokens.push(c);
        });

        const url = _getRelativeURLWithSearchParams(APIConstants.GetUrlData, searchParams);

        const requestOptions = {
            url,
            method: HTTPVerb.GET,
            cancelToken,
            enableCaching: true,
            timeout: ApiConstants.ContentApiTimeout,
        };

        return ApiManager.getAPIInstance().request(requestOptions);
    };
    private static GetContextHeaders(context: string) {
        let headers = {};
        if (context) {
            headers['XX-Context'] = context;
        }

        return headers;
    }
}

const _getRelativeURLWithSearchParams = (url: string, searchParams: object): string => {
    if (!url) return null;

    const formattedUrl = new URL(url, window.location.origin);

    if (searchParams) {
        Object.keys(searchParams).forEach((key) => {
            formattedUrl.searchParams.append(key, searchParams[key]);
        });
    }

    return formattedUrl.toString().replace(formattedUrl.origin, '');
};

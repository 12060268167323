import Container, { Service } from 'typedi';
import { IDeviceTypeData, IUiChannel, MessageBroker } from '@sparkware/uc-sdk-core';
import DeviceType from '../../ui/enums/DeviceType';
import { Utils } from '../../utils';
import { WindowToken } from '../../../injection-tokens';

@Service()
// TODO move tracking utils service in tracking context
export class TrackingContext {
    private _deviceType: string;
    private _subBrandName: string;
    private _ucSessionCorrelationId: string;
    private _utils: Utils;
    private readonly _window: Window;
    private readonly _uiChannel: IUiChannel;

    public get SubBrandName() {
        return this._subBrandName;
    }

    public get DeviceType() {
        return this._getDeviceType();
    }

    constructor() {
        this._window = Container.get(WindowToken);
        this._uiChannel = MessageBroker.getInstance().ui;
        this._utils = Container.get(Utils);
        this._uiChannel.topics.DeviceUpdate.subscribe((deviceType: IDeviceTypeData) => {
            this._deviceType = deviceType.Type;
        });
        this._subBrandName = this._window.pageContextManager.getBrandData().subBrandName;
    }

    public getUcSessionCorrelationId() {
        if (!this._ucSessionCorrelationId) {
            this._ucSessionCorrelationId = this._utils.getCorrelationId();
        }

        return this._ucSessionCorrelationId;
    }

    private _getDeviceType(): string {
        const deviceInformation = this._window.pageContextManager.getDeviceData();

        const deviceType = !this._deviceType
            ? deviceInformation?.isMobile
                ? DeviceType.Mobile
                : DeviceType.PC
            : this._deviceType;

        return deviceType;
    }
}

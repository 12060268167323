import { Brand, ClientProvider } from '../../src/models/enums/general-enums';

export class BrandUtils {
    private static _window: Window;

    public static initialize(windowToken: any) {
        this._window = windowToken;
    }

    public static isPokerClient = (): boolean =>
        this._window.pageContextManager.getClientProviderData()?.name === ClientProvider.Poker;

    public static isKambi = (): boolean =>
        this._window.pageContextManager.getClientProviderData()?.name === ClientProvider.Kambi;

    public static isSpectate = (): boolean =>
        this._window.pageContextManager.getClientProviderData()?.name === ClientProvider.Spectate;

    public static isPIS = (): boolean => {
        const brandId = this._window.pageContextManager.getBrandData().brandId;
        return this.isPokerClient() && brandId === Brand.Sport_Com;
    };

    public static isPIC = (): boolean => {
        const brandId = this._window.pageContextManager.getBrandData().brandId;
        return this.isPokerClient() && brandId === Brand.Sport_Scasino;
    };
}

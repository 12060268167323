import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { IClientsFrameworkPokerNativeService } from './interfaces';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WindowToken } from '../../../injection-tokens';

@Service()
export class ClientsFrameworkPokerNativeService implements IClientsFrameworkPokerNativeService {
    private readonly _logger: ILogger;
    private readonly _window: Window;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger(
            'ClientsFrameworkPokerNativeService',
        );
        this._window = Container.get(WindowToken);
    }

    public onSiteLoaded = async () => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.PokerNativeInterface.onSiteLoaded(),
        );
    };

    public onAutologinSuccess = async () => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.PokerNativeInterface.onAutologinSuccess(),
        );
    };

    public onAutologinFailed = async () => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.PokerNativeInterface.onAutologinFailed(),
        );
    };

    public sendMessage = async (payload: any) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.PokerNativeInterface.sendMessage(payload),
        );
    };

    public performUCAction = async (payload: any) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.PokerNativeInterface.performUCAction(payload),
        );
    };
}

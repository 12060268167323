import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { IBetsUpdateData, ILogger, ISportChannel, MessageBroker } from '@sparkware/uc-sdk-core';
import { ISport } from '../../sport/models/ISport';
import { INativeService } from '../../native/models/INativeService';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class SportChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _sportChannel: ISportChannel;

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    private get _sportPromise(): Promise<ISport> {
        return LoaderManager.Instance.SportLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('SessionChannelSubscriber');
        this._sportChannel = MessageBroker.getInstance().sport;
        this._sportChannel.topics.betsUpdate.subscribe(this.onBestUpdate.bind(this));
        this._sportChannel.topics.preOpenMyBets.subscribe(this.onPreOpenBets.bind(this));
    }

    private async onBestUpdate(data: IBetsUpdateData) {
        this._logger.debug(`[onBestUpdate]] start, data: ${JSON.stringify(data)}`);
        const sport = await this._sportPromise;
        await sport?.onBetsUpdate(data);

        const nativeService = await this._nativeServicePromise;
        if (nativeService) {
            nativeService.betsUpdate(data);
        }
    }

    private async onPreOpenBets() {
        this._logger.debug('[onPreOpenBets]] start');
        const sport = await this._sportPromise;
        await sport?.onPreOpenBets();
    }
}

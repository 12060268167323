import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { IClientsFrameworkLogoutService } from './interfaces';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WindowToken } from '../../../injection-tokens';

@Service()
export class ClientsFrameworkLogoutService implements IClientsFrameworkLogoutService {
    private readonly _logger: ILogger;
    private readonly _window: Window;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ClientsFrameworkLogoutService');
        this._window = Container.get(WindowToken);
    }

    public doLogout = async (reason) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Logout.doLogout(reason),
        );
    };

    public doLogoutAndShowTimeoutDialog = async (kickReasonId?, timeoutKickOptions?) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Logout.doLogoutAndShowTimeoutDialog(
                kickReasonId,
                timeoutKickOptions,
            ),
        );
    };

    public doLogoutRefreshState = async (logoutData: any, kickReasonId?: number) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Logout.doLogoutRefreshState(
                logoutData,
                kickReasonId,
            ),
        );
    };

    public doLogoutWithoutRefresh = async (
        kickReasonId,
        isLogoutFromPlatform,
    ): Promise<boolean> => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready;
        return this._window.PF.Web.ClientsFramework.Logout.doLogoutWithoutRefresh(
            kickReasonId,
            isLogoutFromPlatform,
        );
    };

    public doSoftKick = async (kickReasonId: number, correlationId?: string) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Logout.doSoftKick(kickReasonId, correlationId),
        );
    };

    public removeLobbyCookie = async () => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Logout.removeLobbyCookie(),
        );
    };

    public cleanUserStorageItems = async () =>
        this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.Logout.cleanUserStorageItems(),
        );
}

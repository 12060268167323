import 'polyfill-object.fromentries';
import Container from 'typedi';
import { ClickStreamTrackingProvider } from '../../tracking';
import { ClientState } from '../../user-interaction/enums/Consts';
import { ClientTracking } from '../../tracking/models/clientTracking';
import { EventFormatterBuilderFactory } from '@unified-client/event-formatter';
import { IClientStateChangedData, MessageBroker } from '@sparkware/uc-sdk-core';
import { LocalSimpleStoreService } from '../../storage/implementations/simple-store';
import { LoggerProvider } from '../../logger';
import { StorageItemEnum } from '../../../models/enums/storage-enums';
import { Tracking } from '../../tracking/models/tracking';
import { Utils } from '../../utils';
import {
    WindowToken,
    UserDataStoreDeferredObjectToken,
    GTMDataLayerToken,
    UCFData,
} from '../../../injection-tokens';
import { v4 as uuidv4 } from 'uuid';
import { CookieStoreItemService } from '../../storage/implementations';

export const loadScript = (scriptSrc: string, async = true, type = 'text/javascript') => {
    return new Promise((resolve, reject) => {
        try {
            const scriptEle = document.createElement('script');
            scriptEle.type = type;
            scriptEle.async = async;
            scriptEle.src = scriptSrc;
            scriptEle.addEventListener('load', () => {
                resolve({ status: true });
            });
            scriptEle.addEventListener('error', () => {
                reject({
                    status: false,
                    message: `Failed to load the script ${scriptSrc}`,
                });
            });
            document.body.appendChild(scriptEle);
        } catch (error) {
            reject(error);
        }
    });
};

export const addMaintenance = () => {
    const logger = Container.get(LoggerProvider).getLogger('Maintenance');
    try {
        const maintenanceModeEnabled =
            window.pageContextManager.getSiteData().maintenanceModeEnabled;
        if (maintenanceModeEnabled) {
            setTimeout(() => {
                Container.set(GTMDataLayerToken, window.dataLayer);
                Container.get(Tracking);
                const clientTracking = Container.get(ClientTracking);
                clientTracking.sendMaintenanceEvent();
            }, 0);
        }
    } catch (error) {
        logger.error('addMaintenance failed', error);
    }
};

export const setLoaded = (value: any) => {
    window.libraryManager.UCSDKLibrary?.loaded(value);
};

export const setEntryLoaded = (value: any) => {
    window.libraryManager.UCSDKEntryLibrary?.loaded(value);
};

export const sendCorrelationEvent = async () => {
    const dataStoreReady = Container.get(UserDataStoreDeferredObjectToken);
    await dataStoreReady.promise;

    const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
    const eventFormatterBuilder =
        eventFormatterBuilderFactory.createEventFormatterBuilder('client-init');
    const ucfData = Container.get(UCFData);
    const utils = Container.get(Utils);
    const pageCorrelationId = ucfData?.pageCorrelationId || utils.generateCorrelationID();
    const { versionNumber } = window.pageContextManager.getReleaseData();

    const formatter = eventFormatterBuilder.createFormatter('sendCorrelationEvent');
    const eventName = 'page-correlation-version-event';
    const event = formatter.formatUCEvent(
        { message: eventName },
        { correlationID: utils.generateCorrelationID() },
        {
            event: eventName,
            pageCorrelationId,
            assemblyVersion: versionNumber,
        },
    );

    const clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
    clickStreamTrackingProvider.sendEventV2(event);
};

export const setDocumentEventsListeners = () => {
    const localSimpleStoreService = Container.get(LocalSimpleStoreService);
    const _window = Container.get(WindowToken);
    localSimpleStoreService.set(StorageItemEnum.UserOnBackToForeground, 'true');
    _window.document.addEventListener('visibilitychange', () => {
        const channels = MessageBroker.getInstance();
        const isInBackground =
            _window.document.hidden ||
            _window.document['msHidden'] ||
            _window.document['webkitHidden'];
        const data: IClientStateChangedData = {
            correlationID: uuidv4(),
            clientStateID: isInBackground ? ClientState.Background : ClientState.Foreground,
            clientStateName: isInBackground ? ClientState[2] : ClientState[1],
        };
        if (isInBackground) {
            localSimpleStoreService.set(StorageItemEnum.UserOnBackToForeground, 'true');
        }
        channels.userinteraction.topics.clientStateChanged.publish({ publisher: 'init' }, data);
    });
    _window.addEventListener('pagehide', function () {
        localSimpleStoreService.set(StorageItemEnum.UserOnBackToForeground, 'true');
    });
};

export const setLastProductCookie = () => {
    const cookieStoreItemService = Container.get(CookieStoreItemService);
    cookieStoreItemService.set('lastproduct', 'sport', {});
};

import { Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { IOptimizely } from './interfaces';
import { loadScript } from '../client-integration/init';
import { OptimizelyData } from 'page-context-manager';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const OptimizelyToken = new Token<IOptimizely>('Optimizely');
@Service()
export class OptimizelyLoader extends BaseModuleLoader<IOptimizely> {
    constructor() {
        super('OptimizelyLoader');
    }

    protected _createModuleInstance = async (): Promise<IOptimizely> => {
        const optimizelyData: OptimizelyData = this._window.pageContextManager.getOptimizelyData();
        const { snippet } = optimizelyData;
        const snippetPromise = loadScript(snippet);

        const module = await import(
            /* webpackChunkName: "optimizely/root" */
            './optimizely'
        );
        await snippetPromise;

        return new module.Optimizely();
    };

    protected override _canUse: () => Promise<boolean> = async () => {
        const optimizelyData: OptimizelyData = this._window.pageContextManager.getOptimizelyData();
        return !!optimizelyData?.snippet;
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IOptimizely> => OptimizelyToken;
}

import EnvAccessAuthenticationConsts from '../src/modules/environment-access-auth/constants/EnvAccessAuthenticationConsts';
import { ApiResponse, HTTPVerb } from '../Modules/Utils/API/Interfaces/IApi';
import { ApiManager } from '../Modules/Utils/API/ApiManager';

export interface IEnvAccessAuthenticationRequest {
    password: string;
}

export default class EnvAccessAuthenticationApi {
    public static CheckPassword = async (
        data: IEnvAccessAuthenticationRequest,
    ): Promise<ApiResponse<boolean>> =>
        ApiManager.getAPIInstance().retriedRequest(
            {
                url: EnvAccessAuthenticationConsts.API.Endpoint,
                method: HTTPVerb.POST,
                data,
            },
            3,
            200,
        );
}

import Container, { Service } from 'typedi';

import { ClientsFrameworkOpenClientProductService } from '../external/clients-framework';
import { Utils } from '../utils';
import { ICustomerRelationsRequest } from './models/ICustomerRelationsRequest';
import { WindowToken } from '../../injection-tokens';

@Service()
export class CustomerRelationsService {
    private _customerRelationsTabs: Array<any> = [];
    private readonly _window: Window;
    private readonly _cfOpenClientProductService: ClientsFrameworkOpenClientProductService;
    private readonly _utils: Utils;

    constructor() {
        this._window = Container.get(WindowToken);
        this._cfOpenClientProductService = Container.get(ClientsFrameworkOpenClientProductService);
        this._utils = Container.get(Utils);
    }

    public openCustomerRelations(customerRelationsRequest: ICustomerRelationsRequest) {
        const target = customerRelationsRequest.target || '_blank';

        const isMobile = this._window.pageContextManager.getDeviceData();
        const productOfferingId = 4;
        const productPackageId = 131;

        if (isMobile)
            this.openCustomerRelationsForPC(
                target,
                customerRelationsRequest,
                productOfferingId,
                productPackageId,
            );
        else
            this.openCustomerRelationsForMobile(
                target,
                customerRelationsRequest,
                productOfferingId,
                null,
                productPackageId,
                1,
            );
    }

    public openCustomerRelationsForMobile(
        target,
        customerRelationsOptions,
        productOfferingId,
        subBrandId,
        productPackageId,
        isRealMode?,
    ) {
        const openCustomerRelationsFn = !!customerRelationsOptions.isAuthenticated
            ? this._cfOpenClientProductService.clientAutoLogin
            : this._cfOpenClientProductService.clientOpenUnauthorized;
        const specificNavigation = {
            FeatureType: customerRelationsOptions.featureType,
            PlayerValue: customerRelationsOptions.playerValue,
        };

        if (this._utils.findIfIsNative()) {
            openCustomerRelationsFn(
                productOfferingId,
                subBrandId,
                productPackageId,
                isRealMode,
                encodeURIComponent(JSON.stringify(specificNavigation)),
                'native',
            );
        } else {
            const isIOS = this._window.pageContextManager.getDeviceData().isIOS;

            if (isIOS) {
                const customerRelationsTab = this._customerRelationsTabs.find(function (data) {
                    return data.target == target;
                });

                let customerRelationsTabWindowReference;

                if (customerRelationsTab !== undefined) {
                    if (
                        customerRelationsTab.windowReference !== undefined &&
                        !customerRelationsTab.windowReference.closed
                    )
                        customerRelationsTab.windowReference.close();

                    this._customerRelationsTabs = this._customerRelationsTabs.filter(
                        function (data) {
                            return data.target != target;
                        },
                    );
                }
                customerRelationsTabWindowReference = this._utils.openWindow('', target);

                this._customerRelationsTabs.push({
                    target: target,
                    windowReference: customerRelationsTabWindowReference,
                });

                openCustomerRelationsFn(
                    productOfferingId,
                    subBrandId,
                    productPackageId,
                    isRealMode,
                    encodeURIComponent(JSON.stringify(specificNavigation)),
                    target,
                    undefined,
                    undefined,
                    undefined,
                    customerRelationsTabWindowReference,
                );
            } else
                openCustomerRelationsFn(
                    productOfferingId,
                    subBrandId,
                    productPackageId,
                    isRealMode,
                    encodeURIComponent(JSON.stringify(specificNavigation)),
                    target,
                );
        }
    }

    private openCustomerRelationsForPC(
        target,
        customerRelationsRequest,
        productOfferingId,
        productPackageId,
    ) {
        const specificNavigation = {
            FeatureType: customerRelationsRequest.featureType,
            PlayerValue: customerRelationsRequest.playerValue,
        };
        const openCustomerRelationsFn = !!customerRelationsRequest.isAuthenticated
            ? this._cfOpenClientProductService.clientAutoLogin
            : this._cfOpenClientProductService.clientOpenUnauthorized;

        openCustomerRelationsFn(
            productOfferingId,
            null,
            productPackageId,
            1,
            encodeURIComponent(JSON.stringify(specificNavigation)),
            target,
        );
    }
}

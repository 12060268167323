import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import {
    IWalletChannel,
    MessageBroker,
    IBalanceUpdatedData,
    ILogger,
    IAppContainerReadyPayload,
} from '@sparkware/uc-sdk-core';
import { IOptimizely } from '../../optimizely/interfaces';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { Wallet } from '../../wallet';
import { IAppLauncher } from '../../app-launcher/models';

@Service()
export class WalletChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _walletChannel: IWalletChannel;
    private readonly _wallet: Wallet;

    private get _optimizelyPromise(): Promise<IOptimizely> {
        return LoaderManager.Instance.OptimizelyLoader.Instance;
    }

    private get _appLauncherPromise(): Promise<IAppLauncher> {
        return LoaderManager.Instance.AppLauncherLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('WalletChannelSubscriber');
        this._wallet = Container.get(Wallet);
        this._walletChannel = MessageBroker.getInstance().wallet;
        this._walletChannel.topics.BalanceUpdated.subscribe(this.onBalanceUpdated.bind(this));
        this._walletChannel.topics.UpdateBalance.subscribe(this.onUpdateBalance.bind(this));
        this._walletChannel.topics.BankrollUpdate.subscribe(this.onBankrollUpdate.bind(this));
    }

    private async onBalanceUpdated(data: IBalanceUpdatedData): Promise<void> {
        const optimizely = await this._optimizelyPromise;
        optimizely?.publishLowBalanceUpdate(data);
    }

    private async onUpdateBalance(): Promise<void> {
        this._wallet.onUpdateBalance();
    }

    private async onBankrollUpdate(data: any): Promise<void> {
        this._logger.debug(`[onBankrollUpdate]] start, data: ${JSON.stringify(data)}`);
        const appLauncher = await this._appLauncherPromise;
        await appLauncher?.onBankrollUpdate(data);
    }
}

import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { AppIdentifiers, ILogger, IOpenNativeAppData } from '@sparkware/uc-sdk-core';
import { ClientProvider, IdsOfElements, Brand } from '../../models/enums/general-enums';
import { IAuthenticationFlowFactory, IAuthenticationFlowRunner } from '../authentication/models';
import { AuthenticationFlowType } from '../authentication/enums';
import { BiometricsUtils } from '../biometrics/biometrics.utils';
import { IMyAccount } from './models';
import { NativeUCAppType } from './enums/consts';
import { NativeUtils } from '../native/native.utils';
import { ClientsFrameworkOpenServiceProductService } from '../external/clients-framework';
import { Utils } from '../utils';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { SessionSimpleStoreService } from '../storage/implementations/simple-store';
import { ILoginOptions } from '../authentication/models';
import { Registration } from '../registration/registration';
import { AuthenticationUtils } from '../authentication/utils/authentication.utils';
import { LoaderManager } from '../../loaders/LoaderManager';
import { PendingService } from '../pending/pending.service';
import { FeatureAvailabilityToken, WindowToken } from '../../injection-tokens';
import { IFeatureAvailability } from '../feature/feature-availability/feature-availability-interface';
import { ABTestFeatures } from '../action-handler/enums/consts';

@Service()
export class PlayerService {
    private _manualLoginFlow?: IAuthenticationFlowRunner;
    private readonly _window: Window;
    private readonly _logger: ILogger;
    private readonly _biometricsUtils: BiometricsUtils;
    private readonly _cfOpenServiceProductService: ClientsFrameworkOpenServiceProductService;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;
    private readonly _utils: Utils;
    protected readonly _registration: Registration;
    private readonly _authenticationUtils: AuthenticationUtils;
    private readonly _pendingService: PendingService;
    private readonly _featureAvailability: IFeatureAvailability;

    private get _authenticationFlowFactoryPromise(): Promise<IAuthenticationFlowFactory> {
        return LoaderManager.Instance.AuthenticationFlowFactoryLoader.Instance;
    }

    constructor() {
        this._window = Container.get(WindowToken);
        this._featureAvailability = Container.get(FeatureAvailabilityToken);
        this._logger = Container.get(LoggerProvider).getLogger('PlayerService');
        this._biometricsUtils = Container.get(BiometricsUtils);
        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
        this._cfOpenServiceProductService = Container.get(
            ClientsFrameworkOpenServiceProductService,
        );
        this._utils = Container.get(Utils);
        this._registration = Container.get(Registration);
        this._authenticationUtils = Container.get(AuthenticationUtils);
        this._pendingService = Container.get(PendingService);
    }

    async OpenMyAccount(myAccountModel?: IMyAccount) {
        let specificNavigationObj: any = {
            CallBack: { Method: 'POSTMessage', Target: this._window.location.origin },
        };

        if (myAccountModel?.tabId) specificNavigationObj.Deeplink = { Id: myAccountModel.tabId };

        if (myAccountModel?.showInAppCloseButton) specificNavigationObj.ShowCloseButton = true;

        specificNavigationObj.HideHeader = myAccountModel?.hideHeader || false;
        specificNavigationObj.HideMenu = myAccountModel?.hideMenu || false;

        const isPromotionWidgetEnabled = await this._featureAvailability.FeatureIsEnabled(
            AppIdentifiers.CRMPromotions,
            ABTestFeatures.CRM_PROMOTIONS,
        );
        const { brandId } = this._window.pageContextManager.getBrandData();
        const hideChallenges = isPromotionWidgetEnabled && brandId == Brand.Sport_Com;

        specificNavigationObj.HideChallenges = hideChallenges ?? false;

        const clientProvider = this._window.pageContextManager.getClientProviderData().name;
        if (clientProvider === ClientProvider.Spectate)
            specificNavigationObj.OddsFormat = this._sessionSimpleStoreService.get(
                StorageItemEnum.OddsFormat,
            );

        const { isMobile } = this._window.pageContextManager.getDeviceData();
        if (isMobile) {
            const isNative = this._utils.findIfIsNative();
            if (!isNative) {
                const isOnTouchIdSettings =
                    this._window.location.pathname.indexOf('Touchidsettings') >= 0;
                if (isOnTouchIdSettings) {
                    if (myAccountModel?.tabId) delete specificNavigationObj.Deeplink;
                } else {
                    specificNavigationObj.ViewPort = { Scale: '0.5' };
                }
            }

            const specificNavigation = encodeURIComponent(JSON.stringify(specificNavigationObj));

            this._cfOpenServiceProductService.clientAutoLogin(
                null,
                10,
                specificNavigation,
                '',
                '',
                '',
                100,
                '',
                true,
                false,
            );

            const ucContainer = this._window.document.getElementById(IdsOfElements.UcContainer);
            ucContainer?.style.setProperty('position', 'fixed');
        } else {
            const specificNavigation = encodeURIComponent(JSON.stringify(specificNavigationObj));

            this._cfOpenServiceProductService.clientAutoLogin(
                null,
                10,
                specificNavigation,
                940,
                658,
                myAccountModel?.onClose,
                '',
                '',
                false,
                !myAccountModel?.showInAppCloseButton,
            );

            setTimeout(() => {
                const scrollContainer = this._window.document.getElementById(
                    IdsOfElements.UcContentContainer,
                );
                scrollContainer?.classList.add('noScroll');
            }, 0);
        }
    }

    OpenNRS() {
        if (this._authenticationUtils.getAuthorizationData() == undefined)
            this._registration.openRegistration();
    }

    OpenBetFailed = async () => {
        await this._pendingService.showPending();
    };

    public openMyAccountPlayersLounge() {
        const myAccountModel: IMyAccount = { tabId: 16 };
        this.OpenMyAccount(myAccountModel);
    }

    public OpenNativeApp = (openNativeAppInput: IOpenNativeAppData): void => {
        let nativeSmartLink = null;

        switch (openNativeAppInput?.Type) {
            case NativeUCAppType.Sport888: {
                nativeSmartLink = this._window.pageContextManager.getNativeData()?.sportSmartLink;
                break;
            }
        }

        if (nativeSmartLink) NativeUtils.openExternalUrl(nativeSmartLink);
    };

    async openLogin(options: ILoginOptions = {}) {
        const authenticationFlowFactory = await this._authenticationFlowFactoryPromise;
        this._manualLoginFlow = await authenticationFlowFactory.create(
            AuthenticationFlowType.Regular,
        );
        await this._manualLoginFlow.execute(options);
    }

    openRegister = async ({ onClose }: { onClose: Function } = { onClose: null }) => {
        await this._registration.openRegistration();
    };
}

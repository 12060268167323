import { ApiWrapper } from './API';
import ApiConfiguration from './Configuration/ApiConfiguration';
import ApiInterceptors from './Interceptors/AxiosInterceptors';

let nonServiceInstance: ApiWrapper | null = null;
let apiInstance: ApiWrapper | null = null;

export const ApiManager = {
    initApi(windowToken: Window) {
        const nonServiceApiConfiguration = ApiConfiguration.getNonServiceConfiguration();
        nonServiceInstance = new ApiWrapper(windowToken, nonServiceApiConfiguration);
        ApiInterceptors.registerNonServiceInterceptors(nonServiceInstance.AxiosInstance);

        const defaultApiConfiguration = ApiConfiguration.getDefaultConfiguration(windowToken);
        apiInstance = new ApiWrapper(windowToken, defaultApiConfiguration);
        ApiInterceptors.registerDefaultInterceptors(apiInstance.AxiosInstance);
    },
    getNonServiceInstance(): ApiWrapper {
        if (!nonServiceInstance) {
            throw new Error("NonServiceInstance is not initialized. Call 'initApi' first.");
        }
        return nonServiceInstance;
    },
    getAPIInstance(): ApiWrapper {
        if (!apiInstance) {
            throw new Error("API is not initialized. Call 'initApi' first.");
        }
        return apiInstance;
    },
};

import { AppIdentifiers, OfferingNames } from '@sparkware/uc-sdk-core';

export enum ActionHandlerElasticEvents {
    BetslipIntent = 'betslipIntent',
    CashierIntent = 'cashierIntent',
    ChallengesIntent = 'challengesIntent',
    DepositIntent = 'depositIntent',
    LoginIntent = 'loginIntent',
    OpenCasinoInSport = 'openCasinoInSport',
    OpenChat = 'openChat',
    OpenPromotions = 'openPromotions',
    OpenControlCenterMini = 'openControlCenterMini',
    OpenControlCenterFull = 'openControlCenterFull',
    OpenHelp = 'openHelp',
    OpenPokerGame = 'openPokerGame',
    OpenPokerHistory = 'openPokerHistory',
    OpenTournamentStrip = 'OpenTournamentStrip',
    RegistrationIntent = 'registrationIntent',
    SpectatePageIntent = 'spectatePageIntent',
    SurveyIntent = 'surveyIntent',
    TransactionHistoryIntent = 'transactionHistoryIntent',
    WithdrawIntent = 'withdrawIntent',
    OpenEmail = 'openEmail',
    OpenOnlineHelp = 'openOnlineHelp',
    OpenLink = 'openLink',
    OpenClientPage = 'openClientPage',
    OpenCasino = 'openCasino',
    OpenAppPending = 'openAppPending',
    AutoLogin = 'autoLogin',
    OpenPokerInSport = 'openPokerInSport',
    PokerInSportGenericHandler = 'pokerInSportGenericHandler',
    OpenForgotPasswordHandler = 'openForgotPasswordHandler',
    UpdateApp = 'updateApp',
}

export const ABTestFeatures = {
    ['CONTROL_CENTER']: 'ABTestingSafeZoneToggle',
    [AppIdentifiers.PokerBlast]: 'PokerBlastAvailability',
    [AppIdentifiers.TransactionHistory]: 'TransactionHistoryWidgetAvailability',
    [AppIdentifiers.UnifiedCashier]: 'DepositWidgetAvailability',
    [AppIdentifiers.UnifiedCashout]: 'WithdrawWidgetAvailability',
    ['CASINO_IN_SPORT']: 'CASINO_IN_SPORT',
    [AppIdentifiers.Registration]: 'RegistrationWidgetAvailability',
    [AppIdentifiers.CRMPromotions]: 'PromotionsWidget',
};

export enum FeatureValues {
    On = 'on',
    Off = 'off',
    AB = 'ab',
}

export const ActionStep = {
    EligibilityStep: 'eligibility_check_step',
    ABTestStep: 'ab_test_step',
    MCRStep: 'minimum_capability_requirement_step',
};

export enum ActionHandlerStepType {
    LoginStep = 'login',
    PendingStep = 'pending',
    PendingWithSkipStep = 'pending-with-skip',
    GetTokenStep = 'token',
    EligibilityStep = 'eligibility',
    LocationConfirmation = 'location-confirmation',
}

export const Offering = {
    [OfferingNames.Poker]: 2,
};

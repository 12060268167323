import axios, { AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { HTTPVerb } from '../Interfaces/IApi';

const registerDefaultRequestInterceptor = (axiosInstance): void => {
    axiosInstance.interceptors.request.use(
        (config) => {
            appendCacheBustingData(config);
            appendRequestMetadata(config);

            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
};

const registerServiceRequestInterceptor = (axiosInstance): void => {
    axiosInstance.interceptors.request.use(
        (config) => {
            appendCacheBustingData(config);
            appendRequestContextData(config);
            appendRequestOverrideContextData(config);
            appendRequestClientDeviceData(config);
            appendRequestCorrelationId(config);

            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
};

const registerRequestLoggingInterceptor = (axiosInstance): void => {
    const logRequest = (config: AxiosRequestConfig) => {
        const currentUrl = config.url;
        const parsedUrl = new URL(currentUrl, window.location.origin);

        const eventData = {
            baseURL: config.baseURL,
            event: 'uc-api-request',
            path: currentUrl,
            apiPath: parsedUrl?.pathname,
        };

        window.libraryManager.UCSDKLibrary.ready.then((uc: any) => {
            uc.channels.tracking.topics.sendEvent.publish(
                {
                    publisher: 'api',
                    correlationID: config?.headers?.['X-Correlation-ID'],
                },
                eventData,
            );
        });
    };

    axiosInstance.interceptors.request.use(
        (config) => {
            const clickStreamUrl = window.pageContextManager.getUrlResourceData()?.clickStreamUrl;
            if (config?.url !== clickStreamUrl) {
                logRequest(config);
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
};

const appendRequestMetadata = (config: AxiosRequestConfig): void => {
    config['metadata'] = { startTime: new Date() };
};

const appendCacheBustingData = (config: AxiosRequestConfig): void => {
    if (config.method.toLowerCase() === HTTPVerb.GET.toLowerCase() && !config['enableCaching']) {
        config.params = Object.assign(config.params || {}, {
            _cb: Date.now(),
        });
    }
};

const appendRequestContextData = (config: AxiosRequestConfig): void => {
    let serializedContextData = null;

    if (window.contextData) {
        let contextData = JSON.parse(window.contextData);

        if (contextData.siteUrl) contextData.siteUrl = encodeURI(contextData.siteUrl);

        serializedContextData = JSON.stringify(contextData);
    }

    config.headers['ContextData'] = serializedContextData;
};

const appendRequestOverrideContextData = (config: AxiosRequestConfig): void => {
    var overrideContextData = window.overrideContextData;

    if (overrideContextData) config.headers['OverrideContextData'] = overrideContextData;
};

const appendRequestCorrelationId = (config: AxiosRequestConfig): void => {
    config.headers['X-Correlation-ID'] = uuidv4();
};

const appendRequestClientDeviceData = (config: AxiosRequestConfig): void => {
    let clientDeviceData: any = {
        isiPad: findIfIsiPad(),
        isNative: findIfIsNative(),
        nativeUXVersion: getNativeUXVersion(),
    };

    if (clientDeviceData.isNative && sessionStorage.NativeDataStore) {
        try {
            const nativeDataStore = JSON.parse(sessionStorage.NativeDataStore);
            if (nativeDataStore) {
                clientDeviceData.nativeAppName = nativeDataStore.Bundle;
                clientDeviceData.nativeClientVersion = nativeDataStore.AppVersion;
                clientDeviceData.nativeOS = nativeDataStore.OS;
                clientDeviceData.nativeLanguage = nativeDataStore.Lang;
                clientDeviceData.nativeDeviceType = nativeDataStore.DeviceType;
                clientDeviceData.iosAppleId = nativeDataStore.AppID;
            }
        } catch (err) {
            console.error('API: AxiosInterceptors: appendRequestClientDeviceData: Error:', err);
        }
    }

    config.headers['ClientDeviceData'] = JSON.stringify(clientDeviceData);
};

const registerDefaultResponseInterceptor = (axiosInstance): void => {
    axiosInstance.interceptors.response.use(
        (response) => {
            response.config['metadata'].endTime = new Date();
            response['duration'] =
                response.config['metadata'].endTime - response.config['metadata'].startTime;
            return response;
        },
        (error) => {
            if (axios.isCancel(error)) return Promise.reject(error);
            error.config.metadata.endTime = new Date();
            error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
            return Promise.reject(error);
        },
    );
};
const findIfIsiPad = (): boolean => {
    return (
        navigator &&
        ((navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) ||
            navigator.platform === 'iPad')
    );
};

const findIfIsNative = (): boolean => {
    const isAndroidNative =
        typeof window.WrapperInterface === 'object' && typeof window.NativeInterface != 'undefined';

    if (isAndroidNative) return true;

    const isiOSNative =
        typeof window.NativeInterface != 'undefined' &&
        typeof window.webkit === 'object' &&
        typeof window.webkit.messageHandlers.callbackHandler === 'object';
    if (isiOSNative) return true;

    const nativeUXVersion = getNativeUXVersion();
    if (nativeUXVersion > 0) return true;

    return sessionStorage.NativeDataStore != undefined;
};

const getNativeUXVersion = () => {
    const parameterName = 'nativeUXVersion';

    let version = getQueryStringParameterByName(parameterName);

    if (!version) version = localStorage.getItem(parameterName);

    return version;
};

const getQueryStringParameterByName = (name): any => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    // search for the parameter value, case insensitive
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)', 'i'),
        results = regex.exec(window.location.search);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default {
    registerDefaultInterceptors: (axiosInstance): void => {
        registerRequestLoggingInterceptor(axiosInstance);
        registerDefaultRequestInterceptor(axiosInstance);
        registerServiceRequestInterceptor(axiosInstance);
        registerDefaultResponseInterceptor(axiosInstance);
    },

    registerNonServiceInterceptors: (axiosInstance): void => {
        registerRequestLoggingInterceptor(axiosInstance);
        registerDefaultRequestInterceptor(axiosInstance);
        registerDefaultResponseInterceptor(axiosInstance);
    },
};

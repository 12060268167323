import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { IClientsFrameworkOpenClientProductService } from './interfaces';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WindowToken } from '../../../injection-tokens';

@Service()
export class ClientsFrameworkOpenClientProductService
    implements IClientsFrameworkOpenClientProductService
{
    private readonly _logger: ILogger;
    private readonly _window: Window;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger(
            'ClientsFrameworkOpenClientProductService',
        );
        this._window = Container.get(WindowToken);
    }

    public clientAutoLogin = async (
        productOfferingId,
        subBrandId,
        productPackageId,
        isRealMode,
        additionalActivationParameters,
        target,
        linkId?,
        checkIfTargetStillExists?,
        addToQueryString?,
    ) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.OpenClientProductPackage.clientAutoLogin(
                productOfferingId,
                subBrandId,
                productPackageId,
                isRealMode,
                additionalActivationParameters,
                target,
                linkId,
                checkIfTargetStillExists,
                addToQueryString,
            ),
        );
    };

    public clientOpenUnauthorized = async (
        productOfferingId,
        subBrandId,
        productPackageId,
        isRealMode,
        additionalActivationParameters,
        target,
        linkId?,
        checkIfTargetStillExists?,
        addToQueryString?,
        windowReference?,
    ) => {
        await this._window.libraryManager.ClientsFrameworkLibrary.ready.then(() =>
            this._window.PF.Web.ClientsFramework.OpenClientProductPackage.clientOpenUnauthorized(
                productOfferingId,
                subBrandId,
                productPackageId,
                isRealMode,
                additionalActivationParameters,
                target,
                linkId,
                checkIfTargetStillExists,
                addToQueryString,
                windowReference,
            ),
        );
    };
}

import { ITracking } from './interfaces/ITracking';
import { ITrackingProvider } from '.';
import { LoggerProvider } from '../../logger';
import Container, { Service } from 'typedi';
import { Metric } from 'web-vitals';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WebVitalsElasticTracking } from './webVitalsElasticTracking';

@Service()
export class WebVitalsTracking implements ITracking {
    private _logger: ILogger;
    private readonly _providers: Array<ITrackingProvider>;
    private readonly _elasticTrackingProvider: WebVitalsElasticTracking;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('WebVitalsTracking');
        this._elasticTrackingProvider = Container.get(WebVitalsElasticTracking);
        this._providers = [this._elasticTrackingProvider];
    }

    track = (webVitalsData: Metric) => {
        if (!this._providers || !this._providers.length) {
            this._logger.debug(`No web vitals tracking providers set`);
            return;
        }

        for (let provider of this._providers) provider.track(webVitalsData);
    };
}

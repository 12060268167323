import Container, { Service, Token } from 'typedi';
import { IWebPushService } from './models/IWebPushService';
import { Lock } from '../utils/lockUtil';
import { DeviceUtils } from '../utils/deviceUtils';
import { FeatureAvailabilityToken } from '../../injection-tokens';
import { IFeatureAvailability } from '../feature/feature-availability/feature-availability-interface';
import { Brand, Features } from '../../models/enums/general-enums';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

export const WebPushToken = new Token<IWebPushService>('WebPush');

const moduleLock = new Lock();

@Service()
export class WebPushLoader extends BaseModuleLoader<IWebPushService> {
    private _featureAvailabilityService: IFeatureAvailability;
    private readonly _deviceUtils: DeviceUtils;

    constructor() {
        super('WebPushLoader');
        this._deviceUtils = Container.get(DeviceUtils);
        this._featureAvailabilityService = Container.get(FeatureAvailabilityToken);
    }

    protected override _canUse = async (): Promise<boolean> => {
        const isNativeApp =
            this._deviceUtils.isCasinoApp() ||
            this._deviceUtils.isPokerApp() ||
            this._deviceUtils.isSportHybridApp();

        const airshipEnabled = this._featureAvailabilityService.IsFeatureEnabled(Features.AIRSHIP);
        const { country } = this._window.pageContextManager.getGlobalizationData();
        const { brandId } = this._window.pageContextManager.getBrandData();

        const isComGbrEnabled =
            brandId === Brand.Sport_Com && country?.toLocaleLowerCase() === 'gbr';
        const isNotComEnabled = brandId !== Brand.Sport_Com;

        return airshipEnabled && !isNativeApp && (isComGbrEnabled || isNotComEnabled);
    };

    protected override _createModuleInstance = async (): Promise<IWebPushService> => {
        const module = await import(
            /* webpackChunkName: "web-push/web-push" */
            './web.push.service'
        );

        return new module.WebPushService();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IWebPushService> => WebPushToken;
}

import {
    IGetPendingActionsParams,
    IGetPendingActionsResponse,
} from '../src/modules/pending/interfaces';
import { ApiResponse, HTTPVerb } from '../Modules/Utils/API/Interfaces/IApi';
import { ApiManager } from '../Modules/Utils/API/ApiManager';

enum APIConstants {
    HandlePending = 'api/pending/autologin-data/',
    GetPendingActions = '/api/pending/',
}

export default class PendingApi {
    public static HandlePending = async (data?: any): Promise<any> =>
        ApiManager.getAPIInstance().request({
            url: APIConstants.HandlePending,
            method: HTTPVerb.GET,
            data,
        });

    public static GetPendingActions = async (
        params?: IGetPendingActionsParams,
    ): Promise<ApiResponse<IGetPendingActionsResponse>> =>
        ApiManager.getAPIInstance().authenticatedRequest({
            url: APIConstants.GetPendingActions,
            method: HTTPVerb.GET,
            params,
        });
}

import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { ICommunicationsChannel, ILogger, MessageBroker } from '@sparkware/uc-sdk-core';
import { IAppLauncher, IInAppMessage } from '../../app-launcher/models';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class CommunicationsChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _communicationsChannel: ICommunicationsChannel;

    private get _appLauncherPromise(): Promise<IAppLauncher> {
        return LoaderManager.Instance.AppLauncherLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('CommunicationsChannelSubscriber');
        this._communicationsChannel = MessageBroker.getInstance().communications;
        this._communicationsChannel.topics.InAppMessage.subscribe(this.onInAppMessage.bind(this));
    }

    private async onInAppMessage(data: IInAppMessage): Promise<void> {
        this._logger.debug(`[onInAppMessage]] start, data: ${JSON.stringify(data)}`);
        const appLauncher = await this._appLauncherPromise;
        await appLauncher?.onInAppMessage(data);
    }
}

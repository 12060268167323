import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { WindowToken } from '../../injection-tokens';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class TrackingUtilsService {
    private readonly _logger: ILogger;
    private readonly _window: Window;

    public constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('TrackingUtilsService');
        this._window = Container.get(WindowToken);
    }

    getReportingParameters() {
        const { brandId, subBrandId } = this._window.pageContextManager.getBrandData();
        const { clientVersion, productPackage } = this._window.pageContextManager.getDeviceData();
        const { country } = this._window.pageContextManager.getGlobalizationData();
        const { currency, langId } = this._window.pageContextManager.getLocalizationData();
        const { globalSessionId, playerSessionId } =
            this._window.pageContextManager.getSessionData();
        const { environment } = this._window.pageContextManager.getEnvironmentData();
        const { cid } = this._window.pageContextManager.getUserData();

        return {
            brand: brandId,
            version: clientVersion,
            country: country,
            currency: currency,
            environment: environment,
            globalSessionId: globalSessionId,
            language: langId,
            parentProductPackage: productPackage,
            playerSessionId: playerSessionId,
            productPackage: productPackage,
            productPackageId: productPackage,
            subBrand: subBrandId?.toString(),
            cid: cid,
        };
    }

    getLogV2SessionData() {
        const { globalSessionId, playerSessionId } =
            this._window.pageContextManager.getSessionData();
        return { globalSessionID: globalSessionId, playerSessionID: playerSessionId };
    }

    getLogV2UserData() {
        const { brandId, brandName } = this._window.pageContextManager.getBrandData();
        const { cid } = this._window.pageContextManager.getUserData();

        return { cid: cid, brand: brandName, brandID: brandId };
    }
}

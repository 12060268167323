import { Container, Service, Token } from 'typedi';
import { Lock } from '../utils/lockUtil';
import { IMb2Cb } from './models/IMb2Cb';
import { Utils } from '../utils';
import { BaseModuleLoader } from '../../loaders/BaseModuleLoader';

const moduleLock = new Lock();

export const Mb2CbToken = new Token<IMb2Cb>('Mb2Cb');
@Service()
export class Mb2CbLoader extends BaseModuleLoader<IMb2Cb> {
    private readonly _utils: Utils;

    constructor() {
        super('Mb2CbLoader');
        this._utils = Container.get(Utils);
    }

    protected _createModuleInstance = async (): Promise<IMb2Cb> => {
        const module = await import(
            /* webpackChunkName: "action/app-wrapper/mb-2-cb" */
            './mb-2-cb'
        );
        return new module.Mb2Cb();
    };

    protected override _getModuleLock = (): Lock => moduleLock;
    protected override _getToken = (): Token<IMb2Cb> => Mb2CbToken;

    protected override _canUse = async (): Promise<boolean> => {
        const isAppWrapper = this._utils.isAppWrapper();

        if (!isAppWrapper) {
            this._logger.debug('Mb2Cb module will no loaded since isAppWrapper is ' + isAppWrapper);
        }

        return isAppWrapper;
    };
}

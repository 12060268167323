import Container, { Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { ILogger, NativeAppType } from '@sparkware/uc-sdk-core';
import { Utils } from './utils';
import { WindowToken } from '../../injection-tokens';

@Service()
export class DeviceUtils {
    private readonly _logger: ILogger;
    private readonly _utils: Utils;
    private readonly _window: Window;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('DeviceUtils');
        this._utils = Container.get(Utils);
        this._window = Container.get(WindowToken);
    }

    isSportHybridApp = () => this._utils.findIfIsNative();
    isCasinoApp = () => typeof this._window.isSIC !== 'undefined';
    isPokerApp = () =>
        typeof this._window?.['external']?.['onSiteLoaded'] === 'function' ||
        !!this._window?.pokerNativeInterface;

    getNativeAppType = (): NativeAppType => {
        if (this.isCasinoApp()) return NativeAppType.Casino;
        if (this.isPokerApp()) return NativeAppType.Poker;
        if (this.isSportHybridApp()) return NativeAppType.Sport;

        return null;
    };
}

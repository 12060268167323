import Container, { Service } from 'typedi';

import {
    WebObjectStorageService,
    WebWindowObjectStorageService,
} from '../../../../../Modules/Storage/implementations';
import { LocalStorageToken, SessionStorageToken, WindowToken } from '../../../../injection-tokens';
import { LoggerProvider } from '../../../logger';
import { IStoreService } from '../../interfaces';
import { BaseStoreService } from './BaseStoreService';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class SessionStoreService extends BaseStoreService implements IStoreService {
    protected _logger: ILogger;

    constructor() {
        const sessionStorageToken = Container.get(SessionStorageToken);
        const webObjectStorageService = new WebObjectStorageService(sessionStorageToken);

        super(webObjectStorageService);

        this._logger = Container.get(LoggerProvider).getLogger('SessionStoreService');
    }
}

@Service()
export class LocalStoreService extends BaseStoreService implements IStoreService {
    protected _logger: ILogger;

    constructor() {
        const localStorageToken = Container.get(LocalStorageToken);
        const webObjectStorageService = new WebObjectStorageService(localStorageToken);
        super(webObjectStorageService);
        this._logger = Container.get(LoggerProvider).getLogger('LocalStoreService');
    }
}

@Service()
export class WindowStoreService extends BaseStoreService implements IStoreService {
    protected _logger: ILogger;

    constructor() {
        const windowToken = Container.get(WindowToken);
        const windowStorageItemService = new WebWindowObjectStorageService(windowToken);

        super(windowStorageItemService);

        this._logger = Container.get(LoggerProvider).getLogger('WindowStoreService');
    }
}

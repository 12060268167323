import { ClientIntegrationFacadeToken } from '../../../injection-tokens';
import { ClientSettings } from './client-settings';
import Container from 'typedi';
import { PokerClientIntegrationFacade } from '../poker-client-integration.facade';
import { IClientContainerData, IPokerClientSettings } from '@sparkware/uc-sdk-core';
import { StaticPaths } from '../../../models/enums/general-enums';

export class PokerClientSettings extends ClientSettings {
    constructor() {
        super();
    }

    public getClientSettings = (): IPokerClientSettings => {
        const clientSettingsBase = this.getBaseClientSettings();
        const containers: IClientContainerData = {
            fullScreenPopup: 'uc-full-screen-popup',
            actionIndicator: 'uc-action-indicator-container',
            modalContainer: 'uc-modal-container',
        };
        return {
            ...clientSettingsBase,
            urlPathPrefix: StaticPaths.PokerRoot,
            containers: containers,
        };
    };

    protected registerTokens() {
        super.registerTokens();
        Container.set(ClientIntegrationFacadeToken, new PokerClientIntegrationFacade());
    }
}

import { EventFormatterBuilderFactory, EventFormatterType } from '@unified-client/event-formatter';
import Container from 'typedi';
import LogIndex from '../../tracking/enums/LogIndex';
import { PerformanceManager } from '../../performance/performance-manager';
import { PerformanceMarks } from '../../performance/enums/consts';
import { TrackingContext } from '../../tracking/models/trackingContext';
import { TrackingUtilsService } from '../../tracking/tracking-utils.service';
import { LocalSimpleStoreService } from '../../storage/implementations/simple-store';
import { ThemeService } from '../../theme/theme.service';
import { UrlUtils } from '../../utils/urlUtils';
import { Utils } from '../../utils';
import {
    IClientSettings,
    IKambiClientSettings,
    IPokerClientSettings,
    ISpectateClientSettings,
} from '@sparkware/uc-sdk-core';
import { WindowToken } from '../../../injection-tokens';

export abstract class ClientSettings {
    protected readonly _window: Window;
    private readonly _performanceManager: PerformanceManager;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;
    private readonly _themeService: ThemeService;
    protected readonly _urlUtils: UrlUtils;
    private readonly _utils: Utils;
    private readonly _trackingUtilsService: TrackingUtilsService;
    private readonly _trackingContext: TrackingContext;

    public abstract getClientSettings():
        | IKambiClientSettings
        | ISpectateClientSettings
        | IPokerClientSettings;

    protected constructor() {
        this._window = Container.get(WindowToken);
        this.registerTokens();
        this._trackingContext = Container.get(TrackingContext);
        this._trackingUtilsService = Container.get(TrackingUtilsService);
        this._registerEventFormatting();
        this._performanceManager = Container.get(PerformanceManager);
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
        this._themeService = Container.get(ThemeService);
        this._urlUtils = Container.get(UrlUtils);
        this._utils = Container.get(Utils);
    }

    protected getBaseClientSettings = (): IClientSettings => {
        this.getClientSettingsPerformance();
        const { brandId, subBrandId } = this._window.pageContextManager.getBrandData();
        const { regulationTypeId, brandRegulationTypeId } =
            this._window.pageContextManager.getRegulationData();
        const { productPackage, deviceTypeId, isHybrid, isMobile } =
            this._window.pageContextManager.getDeviceData();
        const { culture, currency, clientLangIso3 } =
            this._window.pageContextManager.getLocalizationData();
        const { country, state } = this._window.pageContextManager.getGlobalizationData();
        const nativeOS = this._localSimpleStoreService.get('NativeOS');
        const nativeOSVersion = this._localSimpleStoreService.get('NativeOSVersion');
        return {
            brandID: brandId,
            country: country,
            currency: currency,
            deviceTypeID: deviceTypeId,
            isHybrid: isHybrid || this._utils.findIfIsNative(),
            isMobile: isMobile,
            languageCode: clientLangIso3,
            locale: culture,
            osName: !!nativeOS ? nativeOS : null,
            osVersion: !!nativeOSVersion ? nativeOSVersion : null,
            productPackageID: productPackage,
            regulationTypeID: regulationTypeId,
            state: state,
            subBrandID: subBrandId,
            brandRegulationTypeID: brandRegulationTypeId,
            themeMode: this._getThemeModeCode(),
            timeZoneUTCOffset: new Date().getTimezoneOffset() / 60,
            userMode: this._utils.getUserMode(),
        };
    };

    protected getClientSettingsPerformance(): void {
        this._performanceManager.startWatch(PerformanceMarks.GetClientSettingsStart);
    }

    protected registerTokens() {}

    private _registerEventFormatting = () => {
        const { versionNumber, buildNumber } = this._window.pageContextManager.getReleaseData();
        const { ipAddressV6, ipAddress } = this._window.pageContextManager.getNetworkData();
        const { serverName, systemId, environment } =
            this._window.pageContextManager.getEnvironmentData();

        const logFormatterBuilderFactory = new EventFormatterBuilderFactory({
            sender: {
                ipv4: ipAddress,
                ipv6: ipAddressV6,
                serverName: serverName,
                systemID: systemId,
                buildNumber: buildNumber,
                versionNumber: versionNumber,
            },
            type: EventFormatterType.Client,
            settings: {
                environment: environment?.toLowerCase(),
                target: LogIndex.UnifiedClient,
                sessionID: this._trackingContext.getUcSessionCorrelationId(),
            },
            sessionData: this._trackingUtilsService.getLogV2SessionData(),
            userData: this._trackingUtilsService.getLogV2UserData(),
        });

        Container.set(EventFormatterBuilderFactory, logFormatterBuilderFactory);
    };

    private _getThemeModeCode = (): number => {
        const themeMode = this._themeService.getThemeModeOrDefault();
        return themeMode?.code;
    };
}

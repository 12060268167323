import { ILoginSuccessData } from '@sparkware/uc-sdk-core';
import { ClientInit } from './client-init';
import { PromotionEligibilityService } from '../../promotion-eligibility';
import Container, { Service } from 'typedi';
import { SessionStorageToken } from '../../../injection-tokens';

@Service()
export class PokerClientInit extends ClientInit {
    private readonly _promotionEligibilityService: PromotionEligibilityService;
    private readonly _sessionStorage: Storage;

    constructor() {
        super();
        this._sessionStorage = Container.get(SessionStorageToken);
        this._promotionEligibilityService = Container.get(PromotionEligibilityService);
    }

    protected override subscribeToMessageBroker() {
        const { messageBrokerSubscribe } = this._clientIntegrationFacade;

        messageBrokerSubscribe(this._messageBrokerSubscription);
        const analyticsDataEvent =
            sessionStorage.analyticsDataEvent && JSON.parse(sessionStorage.analyticsDataEvent);

        if (analyticsDataEvent) {
            const { sendAnalytics } = this._clientIntegrationFacade;
            sendAnalytics(analyticsDataEvent);
            this._sessionStorage.removeItem('analyticsDataEvent');
        }
    }

    protected override async onLoginSuccess(data: ILoginSuccessData) {
        await super.onLoginSuccess(data);
        await this._promotionEligibilityService.checkPromotionEligibilityApiAndPublish();
    }
}

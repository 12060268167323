import { AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';

export enum SDKType {
    Web = 1,
    NativeSDK = 2,
    AppWrapper = 3,
}

export interface ApiResponse<T = any, K = any> {
    response: T;
    duration: number;
    errorResponse: K;
    cancelled: boolean;
    config: AxiosRequestConfig;
    statusCode: number;
    statusText: string;
    errorCode: string | number;
    errorDescription: string;
}

export enum HTTPVerb {
    GET = 'GET',
    DELETE = 'DELETE',
    HEAD = 'HEAD',
    OPTIONS = 'OPTIONS',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
}

export interface IApi {
    AxiosInstance: AxiosInstance;
    createCancelToken(executor: (cancel: (message?: string) => void) => void): CancelToken;
    request(options: AxiosRequestConfig): Promise<Partial<ApiResponse>>;
    retriedRequest(
        options: AxiosRequestConfig,
        retryCount: number,
        delayMsBetweenRetries?: number,
    ): Promise<Partial<ApiResponse>>;
    authenticatedRequest(options: AxiosRequestConfig): Promise<Partial<ApiResponse>>;
}
